import { bevelPositions, frameTypes } from '../config'

export default {
  APP_TYPE: 'omega',
  isSummaryTooltipEnabled: true,
  isCanCreateOrdersForOthersEnabled: true,
  isOrderCancellationEnabled: true,
  hasLogoutInHeader: true,
  orderCancellationTime: 60,
  isBifocalVisualisationEnabled: false,
  showShapeGeometryCenterAtStep2: true,
  showFooterImpressum: false,
  showFooterGdprDoc: false,
  showFooterLegalDoc: false,
  showOrdersTableIdColumn: false,
  hideOrdersSourceSwitch: false, // hide order source switch on HP
  useNativeCookieConsent: true,
  defaultCopyReferenceOnDuplication: false,
  safetyMarginStock: 1.5, // value added to radius
  safetyMarginManufacturer: 1.5, // value added to radius
  isMiLensEnabled: true,
  orderSanitizeStringsVariant: 'FULL', // FULL or SOFTER
  areFtpDeliveryNotesEnabled: true,
  hostConf: {
    isGoToWowEnabled: true,
    wowUrl: '',
    htmlTitle: 'Omega Direct Web',
    logo: {
      publicPath: '/public/images/logo-omega.png?v=2',
    },
    logoSquared: {
      publicPath: '/public/images/logo-omega-squared.png?v=2',
    },
    favicon: {
      publicPath: '/public/favicon-omega.ico?v=3',
    },
    areDeliveryNotesEnabled: true,
    deliveryNotesVersion: 'default',
    isGetOrderPriceEnabled: true,
  },
  uniqueTranslationKeyPrefix: 'omega;',
  versionPrefix: 'o',
  isGoToWowEnabled: true,
  languages: ['en', 'es', 'fr', 'de', 'cs', 'hr', 'ru', 'it', 'pt'],
  isCustomDiameterEnabled: true,
  isEllipticalDiameterEnabled: true,
  isInternalNoteEnabled: true,
  // considerDesignShift: false, // in lens diameter calculation (getFinalRadiiByShapeAndLenses) - not used anymore
  isLensBaseEnabled: true, // base curve field in refractions section
  // select base curve field after diameter selection with options from catalog
  isBaseCurveSelectEnabled: false,
  isSphAndCylInHundredthsEnabled: false,
  sphAndCylInHundredthsGroups: [],
  isWithShapeOnlyLensesEnabled: false,
  showCalcSurfacePoints: true,
  isExpressOrderEnabled: true,
  isExpress48Enabled: false,
  isCenterThicknessEnabled: true,
  step2DefaultBevelPositionForEdgingOrder: undefined,
  step2AutoPolish: true, // in step 2, automatically set polish field based on frame type
  // let user decide wether spectacles (only) lenses should be polished, customizable via group
  isSpectaclesPolishEnabled: false,
  isPinBevelEnabled: false,
  areFramesEnabled: true,
  isUvSelectionEnabled: true,
  isDecentrationEnabled: true,
  isSoapEnabled: true,
  isSortLensOptionsByNameEnabled: true,
  isMandatoryColorPreselectEnabled: true,
  soapWsdlDev: 'order-import-omega-dev', // deprecated
  soapWsdl: 'order-import-omega', // deprecated
  soapDevUrl: 'https://soap.omega.azure.virtii.com',
  soapProdUrl: 'https://soap.omegadirect.cz',

  isFrameCurveStep2Visible: true,

  arePromotionsEnabled: false,

  // enables fetching order prices from LW soap server
  // isGetOrderPriceEnabled: true must be added to hostConf
  isGetPricesFromLWSoapEnabled: true,

  isAccountInfoVisible: false,

  lensFocusTypes: {
    'Single vision': {
      S: 'Single vision conv',
      X: 'Single vision individual',
    },
    A: 'Multifocal',
    Progressive: {
      C: 'Progressive conv',
      F: 'Progressive freeform',
      I: 'Progressive individual',
      U: 'Progressive Duo',
    },
    D: 'Office / Degressive',
    B: 'Bifocal',
  },

  // deprecated, used as fallback
  // set it in keystone frameBrand
  suppliersMap: {
    OME: 'OME',
    '4EY': '4 Eyes',
  },

  individualMenus: {
    // true = mandatory field
    // false = hidden field
    // order matters! Higher wins if two different lenses selected
    FFUIOT: {
      bvd: true,
      panto: true,
      frameBowAngle: true,
    },
    SPT001: {
      bvd: true,
      panto: true,
      frameBowAngle: true,
    },
    FFUINS: {
      bvd: true,
      panto: true,
      frameBowAngle: true,
    },
    FFUSP1: {
      bvd: false,
    },
    FFUSP2: {
      bvd: false,
    },
    FFUSLM: {
      bvd: false,
      panto: false,
      frameBowAngle: false,
    },
    FFUCRB: {
      bvd: {
        isRequired: false,
        min: 6,
        max: 30,
      },
      panto: {
        isRequired: false,
        min: 0,
        max: 30,
      },
      frameBowAngle: {
        isRequired: false,
        min: 0,
        max: 35,
      },
    },
    FFUSP3: {},
  },
  getBevelPositions: () => [
    bevelPositions.AUTO,
    bevelPositions.FRONT,
    bevelPositions.PERCENT,
    bevelPositions.HALF,
  ],
  // should be function returning array, otherwise default - all; check jzo for reference
  getFrameTypes: () => [
    frameTypes.METAL,
    frameTypes.PLASTIC,
    frameTypes.SPECIAL,
    frameTypes.NYLOR,
    frameTypes.DRILLED,
    frameTypes.GROOVEDDRILLED,
  ],
}

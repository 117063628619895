import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, branch, renderComponent } from 'recompose'
import { withTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'
import { useParams } from 'react-router'
import {
  CreateOrderStep1,
  CreateOrderStep2,
  CreateOrderStep3,
  CreateOrderStep4,
} from '../components/order'
import { Steps, Text, PrintOrder, Link, Gap, Col } from '../components'
import { FixedGap } from '../components/Gap'
import Page from '../components/Page'
import { setActiveOrderStep, fetchOrder, setCurrentOrder } from '../../common/orders/actions'
import { selectVca } from '../../common/vca/actions'
import { fields } from '../../common/lib/redux-fields'
import { getFormKeys, constants as fieldNames } from '../../common/schemaConfig'
import { orderTypes, R, L, lensOptionsSelectTypes, appTypeConfig } from '../../common/config'
import {
  validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
} from '../../common/orders/validations'
import { getIsColorRequiredWithCoating } from '../../common/catalogExtensions'
import {
  parseQuery,
  clearLensFieldsValues,
  getIndividualMenuKey,
  getAreOrderFieldsDirty,
  isSphOrCylFieldInHundredths,
  getOptionsBySelectType,
} from '../app/helpers'
import { getLensValuesBySide } from '../../common/helpers'
import { fetchLensSelectList, fetchLens } from '../../common/catalog/actions'
import { showPopup } from '../../common/popups/actions'
import { totalResetOrder } from '../../common/orders/utils'
import links from '../app/links'
import withRouter from '../components/hoc/withRouter'

const { COATING, COLOR } = lensOptionsSelectTypes

const SIDES = [R, L]

const LoaderWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0 10rem;
  margin: -10rem 0;
  top: 0;
  bottom: 0;
`

const DummyHack = styled.div`
  height: 1px;
`

const getStep = location => {
  let result = 1
  if (location && location.pathname) {
    const splitPath = location.pathname.split('/')
    const step = parseInt(splitPath[splitPath.length - 1], 10)
    if ([1, 2, 3, 4].includes(step)) result = step
  }
  return result
}

const getTitle = (step, t) => {
  const titleBase = t('new order')
  switch (step) {
    case 1:
      return `${titleBase} · ${t('step 1')}`
    case 2:
      return `${titleBase} · ${t('step 2')}`
    case 3:
      return `${titleBase} · ${t('step 3')}`
    case 4:
      return `${titleBase} · ${t('step 4')}`
    default:
      return titleBase
  }
}

class CreateOrderPage extends Component {
  constructor(props) {
    super()
    const query = parseQuery(props.location.search)
    this.state = {
      activeStep: getStep(props.location),
      orderId: query._id || null,
      isCopy: !!query.copy || false,
      isSecondPair: !!query.secondPair || false,
      isLoading: !!query._id,
      isNoLensPopupShown: false,
    }
  }

  // TODO: wrong naming: tohle stahne celou objednavku, je to spatne pojmenovany
  loadLens = async () => {
    const { orderId, isCopy, isSecondPair } = this.state
    const { fields, fetchLens, fetchOrder, selectVca } = this.props
    try {
      const { data, meta } = await fetchOrder({
        _id: orderId,
        // shouldFindProductsInCurrentCatalog: isCopy || isSecondPair,
        shouldFindProductsInCurrentCatalog: true,
      }).meta.action.payload
      const values = getLensValuesBySide(data)
      const promises = []
      const lensR = data && data.lensR
      const lensL = data && data.lensL
      const frame = data && data.frame

      // if order is draft and catalog was changed, selected lens still should be enabled
      // disabled now, todo vyřešit s honzou
      // const isCatalogCheckDisabled = !isCopy && !isSecondPair
      const isCatalogCheckDisabled = false
      if (lensR) {
        promises.push(
          fetchLens(lensR, R, { ...values.r, isCatalogCheckDisabled }).meta.action.payload,
        )
      }
      if (lensL) {
        promises.push(
          fetchLens(lensL, L, { ...values.l, isCatalogCheckDisabled }).meta.action.payload,
        )
      }
      if (frame) {
        promises.push(selectVca({ _id: frame, isFrame: true }).meta.action.payload)
      }

      if (meta && meta.dataFromNewCatalog && meta.dataFromNewCatalog.isFrameLost) {
        selectVca()
      }

      await Promise.all(promises)
    } catch (error) {
      // catch api error
      // threw when lens does not exist
    }
    fields.$reset()
    fields.$refreshInitialState()
    if (isCopy) {
      this.cleanOrderAfterCopy()
    }
    if (isSecondPair) {
      fields.$setValue('firstPair', orderId)
    }
  }

  async componentDidMount() {
    const {
      setActiveOrderStep,
      location,
      fields,
      t,
      currentOrder,
      currentOrderPlaceholder,
      currentLensR,
      currentLensL,
      catalogExtensions,
    } = this.props
    const { orderId } = this.state

    const fieldValues = fields.$values()

    // set browser close listener
    const message = t('LEAVE_ORDER_WARNING_MESSAGE')
    // console.log('asdfasf!!!!')
    window.onbeforeunload = event => {
      if (process.env.NODE_ENV !== 'production') {
        return
      }
      // console.log('this.props', this.props)
      const { currentOrder, fields, isOrderFormDirty } = this.props

      // check, if something in fields was changed compared to current order in redux
      const areOrderFieldsDirty = getAreOrderFieldsDirty({
        fieldsValues: fields.$values(),
        currentOrder,
      })

      const isDirty = areOrderFieldsDirty || isOrderFormDirty
      // console.log('isDirty', isDirty, areOrderFieldsDirty , isOrderFormDirty)

      if (isDirty) {
        const e = event || window.event
        if (e) {
          e.preventDefault()
          e.returnValue = message
        }
        return message
      }

      // return
    }

    setActiveOrderStep(getStep(location))

    const validationOptions = {
      step: getStep(location),
    }

    SIDES.forEach(side => {
      const currentLens = side === R ? currentLensR : currentLensL
      if (currentLens) {
        const coatingOptions = getOptionsBySelectType(currentLens.optionsArray, COATING)
        const colorOptions = getOptionsBySelectType(currentLens.optionsArray, COLOR)

        // check catalog extensions for mandatory color
        const isColorRequired = getIsColorRequiredWithCoating({
          coatingId: side === R ? fieldValues.coatingR : fieldValues.coatingL,
          catalogExtensions,
          coatingOptions,
        })
        validationOptions[`mandatoryOptions${side}`] = {
          coating: coatingOptions.isMandatory,
          color: colorOptions.isMandatory || isColorRequired,
        }
      }
    })

    fields.$setValidationOptions(validationOptions)

    // load order only if it is not already loaded
    if (orderId && (!currentOrderPlaceholder || currentOrderPlaceholder._id !== orderId)) {
      totalResetOrder()
      await this.loadLens()
    }
    this.setState({ isLoading: false }) // eslint-disable-line

    // create unique id for every opened form
    // it will be stored as internalId in case this is a new order
    // internalId may already exist in copied orders, that is why the field has different name in browser
    if (!fieldValues.uniqueId) {
      const uniqueId = nanoid()
      fields.$setValue('uniqueId', uniqueId)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentLensR,
      currentLensL,
      fields,
      currentOrder,
      persistentOrderValues,
      currentOrderPlaceholder,
      history,
    } = this.props

    // set ecs
    const sphOrCylInHundredths = isSphOrCylFieldInHundredths(fields)

    SIDES.forEach(side => {
      if (!fields) return
      const ecsFieldKey = [`ecs${side}`]
      const lens = side === R ? currentLensR : currentLensL
      const ecsValue = fields[ecsFieldKey].value
      const setHasEcsValue = value => {
        // ECS is always ECS or nothing
        fields[ecsFieldKey].onChange({ value })
      }

      // clean value if lens is deleted
      if (!lens && ecsValue) setHasEcsValue(null)
      if (!lens) return

      const ecsOptions = getOptionsBySelectType(lens?.optionsArray, lensOptionsSelectTypes.ECS)
      const ecsOption = ecsOptions?.options?.[0]?._id
      // set if sph or cyl in hundredths and option is possible
      if (sphOrCylInHundredths && ecsOptions?.isPossible && !ecsValue) {
        setHasEcsValue(ecsOption)
      }
      // clean value if sph or cyl is not in hundredths or if option is not available anymore
      if (ecsValue && (!sphOrCylInHundredths || !ecsOptions?.isPossible)) {
        setHasEcsValue(null)
      }
    })

    // set individual menu to fields
    const menuConfKey =
      getIndividualMenuKey(
        fields.lensR.value ? currentLensR : null,
        fields.lensL.value ? currentLensL : null,
      ) || ''
    if (fields.individualMenu.value !== menuConfKey) {
      fields.$setValue('individualMenu', menuConfKey)
    }

    // if we fetched fresh data
    if (prevProps.currentOrder === null && currentOrder) {
      // if fields are empty (persistentOrderValues is copy of fields)
      if (!persistentOrderValues) {
        // we want this persistent, cuz of radius calculation, which takes these values directly from reducer...
        const { pdR, pdL, heightR, heightL } = currentOrder

        // update fields with freshly fetched data
        fields.$setValue('pdR', pdR)
        fields.$setValue('pdL', pdL)
        fields.$setValue('heightR', heightR)
        fields.$setValue('heightL', heightL)
      }
    }

    const { isCopy, isSecondPair } = this.state
    this.updateFieldsDiameterValidationConfig()
    if (currentOrderPlaceholder !== prevProps.currentOrderPlaceholder) {
      fields.$refreshInitialState()
    }
    const { orderId, isLoading } = this.state

    if (prevProps.currentOrder && !currentOrder && orderId && !isLoading) {
      this.loadLens()
    }

    // if sent order is accessed by back button, then clean
    // this is sometimes called after order is sent and history is undefined
    // it happens only with a few users and this should fix it
    // I don't think there is a better way to fix this. 
    if (currentOrder && currentOrder.status && history) {
      const split = currentOrder.status.split('_')
      const statusNumber = parseInt(split[0], 10)
      // status over 20 means order was send
      if (!isCopy && !isSecondPair && statusNumber && statusNumber > 20) {
        totalResetOrder()
        try {
          // make sure this does not crash the app
          history?.replace(links.CREATE_ORDER_STEP_1)
        } catch (error) {
          console.notifyWarning(error, {
            customMessage: 'Error při přesměrování po odeslání objednávky objednávky',
          })
        }
      }
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null
  }

  updateFieldsDiameterValidationConfig = () => {
    const { currentLensL, currentLensR, fields } = this.props
    const getMinMax = diameters => {
      const values = diameters.map(d => d.physical)
      return {
        min: Math.min(...values),
        max: Math.max(...values),
      }
    }
    const areMinMaxDifferent = (first = {}, second = {}) =>
      first.min !== second.min || first.max !== second.max
    const newMinMaxR =
      currentLensR && currentLensR.diameters
        ? getMinMax(currentLensR.diameters)
        : { min: 50, max: 80 }
    const newMinMaxL =
      currentLensL && currentLensL.diameters
        ? getMinMax(currentLensL.diameters)
        : { min: 50, max: 80 }
    const { customPairConfig } = fields.$getValidationOptions()
    if (
      areMinMaxDifferent(customPairConfig && customPairConfig.diameterPhysicalR, newMinMaxR) ||
      areMinMaxDifferent(customPairConfig && customPairConfig.diameterPhysicalL, newMinMaxL)
    ) {
      fields.$setValidationOptions({
        customPairConfig: {
          diameterPhysicalR: newMinMaxR,
          diameterPhysicalL: newMinMaxL,
        },
      })
    }
  }

  // TODO prozkoumat - nemělo by fungovat
  getStepContent() {
    const { activeStep } = this.props
    switch (activeStep) {
      case 1:
        return CreateOrderStep1
      case 2:
        return CreateOrderStep2
      case 3:
        return CreateOrderStep3
      case 4:
        return CreateOrderStep4
      default:
        return null
    }
  }

  cleanOrderAfterCopy = () => {
    const { setCurrentOrder, fields, copyReferenceOnDuplication } = this.props
    setCurrentOrder(null)

    const fieldsToClear = [
      fieldNames.firstPair,
      fieldNames.xmlOrderId,
      fieldNames.ftpAddedToBatchAt,
      fieldNames.ftpLastTryAt,
      fieldNames.ftpSentAt,
      fieldNames.status,
      fieldNames.subStatus,
      fieldNames.statusSentToManufacturerAt,
      fieldNames.statusDoneAt,
      fieldNames.statusCancelledAt,
      fieldNames.lastStatusUpdateAt,
      fieldNames.sortDate,
      fieldNames.manufacturerId,
      'createdAt',
      'updatedAt',
    ]

    if (!copyReferenceOnDuplication) {
      fieldsToClear.push(fieldNames.reference)
    }

    fields.$setMultipleValues(fieldsToClear.map(fieldName => ({ fieldName, value: '' })))
    // fields.$refreshInitialState()
    if (this.state.isCopy) this.setState({ orderId: null, isCopy: false, isSecondPair: false })
    // REVIEW: cisteni statu isCopy a isSecondPair je asi zbytecne...
  }

  showNoLensFoundPopup = () => {
    const { showPopup, fields, t } = this.props
    this.setState({ isNoLensPopupShown: true })
    showPopup('confirm', {
      title: t('Lens catalog was changed'),
      text: t('Please choose lens type again'),
      onConfirm: () => null,
      onCancel: () => null,
      onClose: () => {
        clearLensFieldsValues({ fields, side: R, cleanLens: true })
        clearLensFieldsValues({ fields, side: L, cleanLens: true })
        this.setState({ isLoading: false })
      },
    })
  }

  showSomethingLostAfterCopyPopup = () => {
    const { t, showPopup } = this.props
    this.setState({ isNoLensPopupShown: true })
    showPopup('confirm', {
      title: t('Lens catalog was changed'),
      text: t('Some data has been lost'),
      onConfirm: () => null,
      onCancel: () => null,
      onClose: () => null,
    })
  }

  render() {
    const {
      fields,
      activeStep,
      currentOrder,
      currentOrderPlaceholder,
      t,
      lensNotFound,
      isSomethingLostAfterCopy,
      viewer,
    } = this.props
    const Content = this.getStepContent()
    const { orderId, isLoading, isNoLensPopupShown } = this.state
    if (!isNoLensPopupShown && (lensNotFound || isSomethingLostAfterCopy)) {
      // this.showNoLensFoundPopup()
      this.showSomethingLostAfterCopyPopup()
    }

    return (
      <PrintOrder
        orderValues={fields.$values()}
        activeStep={activeStep}
        fields={fields}
        showCalculation={activeStep === 3}
      >
        <Page metaTitle={getTitle(activeStep, t)}>
          {isLoading && (
            <LoaderWrapper>
              <Text big>{t('Loading')}</Text>
            </LoaderWrapper>
          )}
          {((currentOrder && orderId) || !orderId || currentOrderPlaceholder) && (
            <>
              <DummyHack />
              <Steps fields={fields} activeStep={activeStep} />
              <Page.Content>
                <Content fields={fields} isLoading={isLoading} />
              </Page.Content>
            </>
          )}

          {!currentOrderPlaceholder && !currentOrder && !isLoading && orderId && (
            <Page.Content>
              <Col>
                <Col maxWidth="500px" alignSelf="center" alignItems="center">
                  <Gap gap="8rem" />
                  <Text size="4rem">{t('Order not found')}</Text>
                  <Gap gap="4rem" />
                  <Text lineHeight="2.4rem" center>
                    {t('You probably opened an order that does not belong to your account.')}
                    <br />
                    {t('You are logged as')} {viewer.username} ({viewer.name}).
                  </Text>
                  <Gap gap="7rem" />
                  <Link to="/">{t('Back to homepage')}</Link>
                </Col>
              </Col>
            </Page.Content>
          )}
        </Page>
      </PrintOrder>
    )
  }
}

CreateOrderPage.defaultProps = {
  activeStep: 0,
  selectedVca: undefined,
  currentLensL: null,
  currentLensR: null,
  currentOrderPlaceholder: null,
  isSomethingLostAfterCopy: false,
  currentOrder: null,
}

CreateOrderPage.propTypes = {
  setActiveOrderStep: PropTypes.func.isRequired,
  activeStep: PropTypes.number,
  fields: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  selectedVca: PropTypes.object,
  t: PropTypes.func.isRequired,
  setCurrentOrder: PropTypes.func.isRequired,
  fetchLensSelectList: PropTypes.func.isRequired,
  fetchLens: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  currentLensL: PropTypes.object,
  currentLensR: PropTypes.object,
  lensNotFound: PropTypes.bool.isRequired,
  showPopup: PropTypes.func.isRequired,
  viewer: PropTypes.object.isRequired,
  currentOrderPlaceholder: PropTypes.object,
  isSomethingLostAfterCopy: PropTypes.bool,
  currentOrder: PropTypes.object,
  history: PropTypes.object.isRequired,
}

const enhance = compose(
  withRouter,
  withStepsRerender,
  connect(
    ({ orders, vca, fields, catalog, app, config }) => ({
      activeStep: orders.activeStep,
      currentOrder: orders.currentOrder,
      currentOrderPlaceholder: orders.currentOrderPlaceholder,
      currentId: orders.currentId,
      isSomethingLostAfterCopy: orders.isSomethingLostAfterCopy,
      persistentOrderValues: fields.OrderForm,
      selectedVca: vca.selectedVca,
      currentLensR: catalog.currentLensR,
      currentLensL: catalog.currentLensL,
      lensNotFound: catalog.lensNotFound,
      viewer: app.viewer,
      isOrderFormDirty: orders.isOrderFormDirty,
      copyReferenceOnDuplication: config.copyReferenceOnDuplication,
      selectVca: PropTypes.func.isRequired,
      defaultChamfer: config.defaultChamfer,
      catalogExtensions: catalog.catalogExtensions,
    }),
    {
      setActiveOrderStep,
      fetchOrder,
      setCurrentOrder,
      fetchLensSelectList,
      fetchLens,
      showPopup,
      selectVca,
    },
  ),
  fields({
    path: 'OrderForm',
    cleanValuesEnabled: true,
    validationFunc: ({ dataToValidate, allData, options, ...rest }) => {
      const step = options ? options.step : 2
      // console.log('>>>> step', step, rest, dataToValidate, allData, 'options', options)
      switch (step) {
        case 1:
          return validateStep1({ dataToValidate, allData, ...options })
        case 2:
          return validateStep2({ dataToValidate, allData, ...rest, ...options })

        default:
          return validateStep1({ dataToValidate, allData })
      }
    },
    getInitialState: ({
      currentOrder,
      selectedVca,
      currentOrderPlaceholder,
      currentLensL,
      defaultChamfer,
      location,
    }) => {
      let { lensR, lensL, coatingR, coatingL, colorR, colorL, baseCurveL, baseCurveR } =
        currentOrder || {}
      if (lensR && lensR._id) lensR = lensR._id
      if (lensL && lensL._id) lensL = lensL._id
      if (coatingR && coatingR._id) coatingR = coatingR._id
      if (coatingL && coatingL._id) coatingL = coatingL._id
      if (colorR && colorR._id) colorR = colorR._id
      if (colorL && colorL._id) colorL = colorL._id
      const {
        diameterPhysicalR,
        diameterPhysicalL,
        diameterOpticalR,
        diameterOpticalL,
        isEllipticalR,
        isEllipticalL,
      } = currentOrder || {}

      // 1. v objednávce udělat switch vevnitř a toto nepřerendrovávat při změně kroku - pokud to lze nějak rozumně udělat
      // teoreticky ale pořád můžu zavolat $refreshInitial state, který tuto funkci znovu zavolá již bez current order
      // 2. current order nemazat, ale je potřeba dát pozor, aby se vytvořila nová objednávka a nepřepsala se stará
      // tedy nejspíš kontrola při odeslání nebo uložení draftu

      const result = {
        isLeftLensSameAsRight:
          lensR === lensL &&
          coatingR === coatingL &&
          colorR === colorL &&
          diameterPhysicalR === diameterPhysicalL &&
          diameterOpticalR === diameterOpticalL &&
          isEllipticalR === isEllipticalL,
        canBeLeftSameAsRight: true,
        isDiameterCustomR: false,
        isDiameterCustomL: false,
        rightLensEnabled: true,
        leftLensEnabled: true,
        orderType: orderTypes.DIAMETER_ONLY,
        frameType: selectedVca && selectedVca.frameType,
        pinBevel: defaultChamfer,
        // this is read only. It makes easier passing of survey result to api call actions
        surveyResult: currentOrder?.survey?.result,
        ...currentOrderPlaceholder,
        ...currentOrder,
      }

      if (appTypeConfig.isBaseCurveSelectEnabled) {
        if (baseCurveR !== baseCurveL) {
          result.isLeftLensSameAsRight = false
        }
      }

      if (lensR) result.lensR = lensR
      if (lensL) result.lensL = lensL
      if (coatingR) result.coatingR = coatingR
      if (coatingL) result.coatingL = coatingL
      if (colorR) result.colorR = colorR
      if (colorL) result.colorL = colorL

      return result
    },
    // getPersistentInitialState: ({ currentOrder, selectedVca }) => ({
    //   ...currentOrder,
    // }),
    fields: [
      ...getFormKeys(),
      'isLeftLensSameAsRight',
      'isDiameterCustomR',
      'isDiameterCustomL',
      'individualMenu',
      'createdAt', // only for display in summary
      'uniqueId', // id generated in browser to prevent duplicit sends
      'surveyResult', // save survey result in fields for easier passing to api call actions
    ],
  }),
  withTranslation(),
)

export default enhance(CreateOrderPage)

// this enforce rerender of the whole CreateOrderPage component on route change
// we need this, 'cuz active step is taken from url on didMount
// this implementation is not ideal and should be refactored
function withStepsRerender(Component) {
  function ComponentWithRouterProp(props) {
    const params = useParams()

    return <Component {...props} key={params.step} />
  }

  return ComponentWithRouterProp
}

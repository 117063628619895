import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Text, Gap, Button } from '../components'
import Popup from '../components/popups/Popup'
import T from '../components/T'
import { CheckboxField, TextField } from '../components/fields'
import { Link } from '../components/Link'
import { appTypeConfig } from '../../common/config'
import { fieldsConfig } from '../../common/schemaConfig'

const SaveSurveyPopup = ({ children, onSubmit }) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language.split('-')[0]

  const [clientName, setClientName] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false)
  const useCustomPrivacyPolicy =
    appTypeConfig.milensPrivacyPolicyCustomLanguages?.includes(lang)
  const dataToSave = { clientName }

  const onSave = async (...params) => {
    setIsSaving(true)
    const result = await onSubmit(...params)
    if (!result) setIsSaving(false)
  }

  return (
    <Popup
      minWidth="50rem"
      title={t('omega; Save to orders')}
      text={
        <Col>
          <Text>
            {t('omega; Fill customer name so you can find the order later.')}
          </Text>
          <Gap gap="2rem" />
          <Col maxWidth="30rem">
            <TextField
              name="patientName"
              label={t('omega; Patient name')}
              value={clientName}
              onChange={e => setClientName(e.value)}
              maxLength={fieldsConfig.clientName.maxLength}
            />
          </Col>

          <Gap gap="1.6rem" />

          <CheckboxField
            name="agreement"
            label={t(
              'omega; The patient agrees with using their personal information',
            )}
            tooltip={
              <Text color="white">
                <T>omega; You can read all terms and conditions</T>{' '}
                <Link
                  external
                  white
                  href={`/public/${
                    useCustomPrivacyPolicy ? `${appTypeConfig.APP_TYPE}/` : ''
                  }privacy_policy_${
                    useCustomPrivacyPolicy ? lang : lang === 'cs' ? lang : 'en'
                  }.pdf`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <T>omega; here</T>
                </Link>
                .
              </Text>
            }
            value={isPrivacyPolicyChecked}
            onChange={() => setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked)}
          />

          <Gap gap="4rem" />

          <Row justifyContent="center">
            <Button
              disabled={!isPrivacyPolicyChecked || isSaving}
              onClick={() => onSave(dataToSave)}
            >
              <T>omega; Save and close</T>
            </Button>
            <Gap />

            <Button
              disabled={!isPrivacyPolicyChecked || isSaving}
              onClick={() => onSave(dataToSave, { shouldOpenOrder: true })}
            >
              <T>omega; Save and open the order</T>
            </Button>
          </Row>
        </Col>
      }
      closeOnEsc
      showControlButtons={false}
    >
      {children}
    </Popup>
  )
}

export default SaveSurveyPopup

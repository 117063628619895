import { apiGet } from '../lib/api'
import { R, L, appTypeConfig } from '../config'
import { getAutoSelectedDiameters } from '../helpers'

export const FETCH_LENS_SELECT_LIST_R_START = 'FETCH_LENS_SELECT_LIST_R_START'
export const FETCH_LENS_SELECT_LIST_R_SUCCESS = 'FETCH_LENS_SELECT_LIST_R_SUCCESS'
export const FETCH_LENS_SELECT_LIST_R_ERROR = 'FETCH_LENS_SELECT_LIST_R_ERROR'

export const FETCH_LENS_SELECT_LIST_L_START = 'FETCH_LENS_SELECT_LIST_L_START'
export const FETCH_LENS_SELECT_LIST_L_SUCCESS = 'FETCH_LENS_SELECT_LIST_L_SUCCESS'
export const FETCH_LENS_SELECT_LIST_L_ERROR = 'FETCH_LENS_SELECT_LIST_L_ERROR'

export const FETCH_LENS_START = 'FETCH_LENS_START'
export const FETCH_LENS_SUCCESS = 'FETCH_LENS_SUCCESS'
export const FETCH_LENS_ERROR = 'FETCH_LENS_ERROR'

export const FETCH_CATALOG_EXTENSIONS_START = 'FETCH_CATALOG_EXTENSIONS_START'
export const FETCH_CATALOG_EXTENSIONS_SUCCESS = 'FETCH_CATALOG_EXTENSIONS_SUCCESS'
export const FETCH_CATALOG_EXTENSIONS_ERROR = 'FETCH_CATALOG_EXTENSIONS_ERROR'

export const FETCH_FRAME_BRANDS_START = 'FETCH_FRAME_BRANDS_START'
export const FETCH_FRAME_BRANDS_SUCCESS = 'FETCH_FRAME_BRANDS_SUCCESS'
export const FETCH_FRAME_BRANDS_ERROR = 'FETCH_FRAME_BRANDS_ERROR'

export const FETCH_LENS_R_START = 'FETCH_LENS_R_START'
export const FETCH_LENS_R_SUCCESS = 'FETCH_LENS_R_SUCCESS'
export const FETCH_LENS_R_ERROR = 'FETCH_LENS_R_ERROR'

export const FETCH_LENS_L_START = 'FETCH_LENS_L_START'
export const FETCH_LENS_L_SUCCESS = 'FETCH_LENS_L_SUCCESS'
export const FETCH_LENS_L_ERROR = 'FETCH_LENS_L_ERROR'

export const FETCH_FRAMES_START = 'FETCH_FRAMES_START'
export const FETCH_FRAMES_SUCCESS = 'FETCH_FRAMES_SUCCESS'
export const FETCH_FRAMES_ERROR = 'FETCH_FRAMES_ERROR'

export const FETCH_PRICE_TYPES_START = 'FETCH_PRICE_TYPES_START'
export const FETCH_PRICE_TYPES_SUCCESS = 'FETCH_PRICE_TYPES_SUCCESS'
export const FETCH_PRICE_TYPES_ERROR = 'FETCH_PRICE_TYPES_ERROR'

export const FETCH_LENS_PRICE_R_START = 'FETCH_LENS_PRICE_R_START'
export const FETCH_LENS_PRICE_R_SUCCESS = 'FETCH_LENS_PRICE_R_SUCCESS'
export const FETCH_LENS_PRICE_R_ERROR = 'FETCH_LENS_PRICE_R_ERROR'

export const FETCH_LENS_PRICE_L_START = 'FETCH_LENS_PRICE_L_START'
export const FETCH_LENS_PRICE_L_SUCCESS = 'FETCH_LENS_PRICE_L_SUCCESS'
export const FETCH_LENS_PRICE_L_ERROR = 'FETCH_LENS_PRICE_L_ERROR'

export const VALIDATE_LENS_R_PARAMS_START = 'VALIDATE_LENS_R_PARAMS_START'
export const VALIDATE_LENS_R_PARAMS_SUCCESS = 'VALIDATE_LENS_R_PARAMS_SUCCESS'
export const VALIDATE_LENS_R_PARAMS_ERROR = 'VALIDATE_LENS_R_PARAMS_ERROR'

export const VALIDATE_LENS_L_PARAMS_START = 'VALIDATE_LENS_L_PARAMS_START'
export const VALIDATE_LENS_L_PARAMS_SUCCESS = 'VALIDATE_LENS_L_PARAMS_SUCCESS'
export const VALIDATE_LENS_L_PARAMS_ERROR = 'VALIDATE_LENS_L_PARAMS_ERROR'

export const CLEAR_CURRENT_LENS = 'CLEAR_CURRENT_LENS'

export const SET_SHOULD_UPDATE_LENSES_LIST = 'SET_SHOULD_UPDATE_LENSES_LIST'
export const SET_CAN_SELECT_STOCK_LENS = 'SET_CAN_SELECT_STOCK_LENS'

export const RESET_CATALOG = 'RESET_CATALOG'

export const fetchLensSelectList = (customQuery, side, fieldsValues) => ({ getState, fetch }) => {
  const query = customQuery
  if (fieldsValues && fieldsValues.orderType) {
    query.orderType = fieldsValues.orderType
  }

  // surveyResult is always a groupName used for filtering
  if (fieldsValues?.surveyResult) query.groupName = fieldsValues.surveyResult

  return {
    type: side === L ? 'FETCH_LENS_SELECT_LIST_L' : 'FETCH_LENS_SELECT_LIST_R',
    payload: apiGet({
      fetch,
      endpoint: 'catalog/lenses',
      getState,
      customQuery: query,
    }),
  }
}

export const fetchFramesList = () => ({ getState, fetch }) => ({
  type: 'FETCH_FRAMES',
  payload: apiGet({
    fetch,
    endpoint: 'catalog/frames',
    getState,
  }),
})

export const fetchCatalogExtensions = () => ({ getState, fetch }) => ({
  type: 'FETCH_CATALOG_EXTENSIONS',
  payload: apiGet({
    fetch,
    endpoint: 'catalog/extensions',
    getState,
  }),
})

export const fetchFrameBrands = () => ({ getState, fetch }) => ({
  type: 'FETCH_FRAME_BRANDS',
  payload: apiGet({
    fetch,
    endpoint: 'catalog/frame-brands',
    getState,
  }),
})

export const validateLensParams = ({ id, customQuery, side, fieldValues }) => ({
  getState,
  fetch,
}) => {
  // přidat pomocnou proměnnou pro BASECURVE check
  if (appTypeConfig.isBaseCurveSelectEnabled) {
    // todo - toto celé dělat jen když je diameter počítaný
    //
    const state = getState()
    const { currentLensR, currentLensL } = state.catalog
    const { selectedVca } = state.vca
    const autoSelectedDiameter = getAutoSelectedDiameters({
      selectedVca,
      lensR: currentLensR,
      lensL: currentLensL,
      fieldValues,
    })

    Object.assign(customQuery, autoSelectedDiameter[side])
  }

  return {
    type: side === L ? 'VALIDATE_LENS_L_PARAMS' : 'VALIDATE_LENS_R_PARAMS',
    payload: apiGet({
      fetch,
      endpoint: `catalog/lenses/${id}/are-params-valid`,
      getState,
      customQuery,
    }),
  }
}

// TODO sjednotit pořadí argumentů - nebo spíš udělat jako objekt
export const fetchLens = (id, side, customQuery) => ({ getState, fetch }) => ({
  // type: (side === L) ? 'FETCH_LENS_L' : 'FETCH_LENS_R',
  type: 'FETCH_LENS',
  payload: apiGet({
    fetch,
    endpoint: `catalog/lenses/${id}`,
    getState,
    customQuery,
  }),
  side,
})

// not used now
export const fetchPriceTypes = () => ({ getState, fetch }) => ({
  type: 'FETCH_PRICE_TYPES',
  payload: apiGet({
    fetch,
    endpoint: 'catalog/price-types',
    getState,
  }),
})

export const fetchLensPrice = (id, side, customQuery) => ({ getState, fetch }) => ({
  type: side === R ? 'FETCH_LENS_PRICE_R' : 'FETCH_LENS_PRICE_L',
  payload: apiGet({
    fetch,
    endpoint: `catalog/lenses/${id}/price`,
    getState,
    customQuery,
  }),
})

export const clearCurrentLens = (side = R) => ({
  type: CLEAR_CURRENT_LENS,
  payload: {
    side,
  },
})

export const setShouldUpdateLensesList = (value, side = R) => ({
  type: SET_SHOULD_UPDATE_LENSES_LIST,
  payload: {
    value,
    side,
  },
})

export const setCanSelectStockLens = (value, side = R) => ({
  type: SET_CAN_SELECT_STOCK_LENS,
  payload: {
    value,
    side,
  },
})

export const resetCatalog = () => ({
  type: RESET_CATALOG,
})

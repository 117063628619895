import { apiGet, apiPost, apiPostFile, apiDelete } from '../lib/api'

export const SET_VISUALISER_SCALE = 'SET_VISUALISER_SCALE'

export const SET_DRAWING_VCA = 'SET_DRAWING_VCA'
export const SAVE_DRAWING_VCA = 'SAVE_DRAWING_VCA'
export const UPDATE_VCA_DRAW_POINT = 'UPDATE_VCA_DRAW_POINT'
export const NORMALIZE_DRAW_VCA = 'NORMALIZE_DRAW_VCA'
export const SMOOTH_DRAW_VCA = 'SMOOTH_DRAW_VCA'
export const CENTER_DRAW_VCA = 'CENTER_DRAW_VCA'
export const FLIP_DRAW_VCA_HORIZONTALLY = 'FLIP_DRAW_VCA_HORIZONTALLY'

export const DEFORM_THUMBS_VERTICAL = 'DEFORM_THUMBS_VERTICAL'
export const DEFORM_THUMBS_HORIZONTAL = 'DEFORM_THUMBS_HORIZONTAL'

export const DEFORM_SELECTED = 'DEFORM_SELECTED'
export const DEFORM_SELECTED_BOX = 'DEFORM_SELECTED_BOX'
export const SAVE_DEFORMED_SELECTED = 'SAVE_DEFORMED_SELECTED'
export const RESET_DEFORMED_SELECTED = 'RESET_DEFORMED_SELECTED'
export const SELECT_VCA = 'SELECT_VCA'
export const CLEAR_CALCULATED_VCA = 'CLEAR_CALCULATED_VCA'
export const CLEAR_CALCULATED_COMPARED_VCA = 'CLEAR_CALCULATED_COMPARED_VCA'
export const SET_SELECTED_VCA_DBL = 'SET_SELECTED_VCA_DBL'
export const SET_SELECTED_VCA_VALUES = 'SET_SELECTED_VCA_VALUES'
export const SET_WANT_CALCULATION = 'SET_WANT_CALCULATION'

// export const SAVE_HOLES = 'SAVE_HOLES'
export const ADD_OR_EDIT_HOLE = 'ADD_OR_EDIT_HOLE'
export const REMOVE_ALL_HOLES = ' REMOVE_ALL_HOLES'

export const FETCH_VCA_START = 'FETCH_VCA_START'
export const FETCH_VCA_SUCCESS = 'FETCH_VCA_SUCCESS'
export const FETCH_VCA_ERROR = 'FETCH_VCA_ERROR'

export const FETCH_ONE_VCA_START = 'FETCH_ONE_VCA_START'
export const FETCH_ONE_VCA_SUCCESS = 'FETCH_ONE_VCA_SUCCESS'
export const FETCH_ONE_VCA_ERROR = 'FETCH_ONE_VCA_ERROR'

export const FETCH_TRACED_VCA_SUCCESS = 'FETCH_TRACED_VCA_SUCCESS'

export const CALC_VCA_START = 'CALC_VCA_START'
export const CALC_VCA_SUCCESS = 'CALC_VCA_SUCCESS'
export const CALC_VCA_ERROR = 'CALC_VCA_ERROR'

export const SAVE_VCA_TO_MY_SHAPES_SUCCESS = 'SAVE_VCA_TO_MY_SHAPES_SUCCESS'
export const SAVE_VCA_TO_MY_SHAPES_ERROR = 'SAVE_VCA_TO_MY_SHAPES_ERROR'

export const DELETE_MY_SHAPE = 'DELETE_MY_SHAPE'
export const DELETE_MY_SHAPE_START = 'DELETE_MY_SHAPE_START'
export const DELETE_MY_SHAPE_SUCCESS = 'DELETE_MY_SHAPE_SUCCESS'
export const DELETE_MY_SHAPE_ERROR = 'DELETE_MY_SHAPE_ERROR'

export const UPLOAD_VCA_SUCCESS = 'UPLOAD_VCA_SUCCESS'
export const UPLOAD_VCA_ERROR = 'UPLOAD_VCA_ERROR'

export const PICK_ONE_LENS = 'PICK_ONE_LENS'

export const SET_HOLES_FOR_EDIT = 'SET_HOLES_FOR_EDIT'
export const SAVE_HOLES_AFTER_EDIT = 'SAVE_HOLES_AFTER_EDIT'

export const CALC_RADIUS = 'CALC_RADIUS'

export const INVALIDATE_CALCULATION = 'INVALIDATE_CALCULATION '

export const SET_ORIGINAL_VCA = 'SET_ORIGINAL_VCA'
export const RESET_TO_ORIGINAL_VCA = 'RESET_TO_ORIGINAL_VCA'

// set current vca as original
// it will be set only if original is empty
export const setOriginalVca = () => ({
  type: SET_ORIGINAL_VCA,
})

// reset selectedVca to originalVca
export const resetToOriginalVca = () => ({
  type: RESET_TO_ORIGINAL_VCA,
})

export const fetchVca = query => ({ getState, fetch }) => ({
  type: 'FETCH_VCA',
  payload: apiGet({
    fetch,
    endpoint: 'vca',
    getState,
    query,
  }),
})

export const fetchTracedVca = ({ tracerUuid } = {}) => ({ getState, fetch }) => ({
  type: 'FETCH_TRACED_VCA',
  payload: apiGet({
    fetch,
    endpoint: `vca/traced?tracerUuid=${tracerUuid}`,
    getState,
  }),
})

export const calcVca3d = ({ orderValues, comparing, lens }) => ({ getState, fetch }) => {
  const vca = {
    ...orderValues,
  }
  return {
    type: 'CALC_VCA',
    comparing,
    lens,
    payload: apiPost({
      fetch,
      endpoint: 'vca/calc',
      getState,
      body: { vca },
    }),
  }
}
export const saveVcaToMyShapes = ({ vca, name }) => ({ getState, fetch }) => ({
  type: 'SAVE_VCA_TO_MY_SHAPES',
  payload: apiPost({
    fetch,
    endpoint: 'vca/myShapes',
    getState,
    body: { vca, name },
  }),
})
export const deleteMyShape = _id => ({ getState, fetch }) => ({
  type: DELETE_MY_SHAPE,
  _id,
  payload: apiDelete({
    fetch,
    endpoint: `vca/myShapes/${_id}`,
    getState,
  }),
})

export const uploadVca = ({ files }) => ({ getState, fetch }) => ({
  type: 'UPLOAD_VCA',
  payload: apiPostFile({
    fetch,
    endpoint: 'vca/file',
    getState,
    body: { files },
  }),
})

export const toggleScale = () => ({ getState }) => {
  const state = getState()
  return {
    type: SET_VISUALISER_SCALE,
    payload: {
      scale: state.vca.scale ? null : state.config.visualiserScale,
    },
  }
}

export const setVisualiserScale = scale => ({
  type: SET_VISUALISER_SCALE,
  payload: { scale },
})

export const deformThumbsVertical = vbox => ({
  type: DEFORM_THUMBS_VERTICAL,
  payload: { vbox },
})

export const deformThumbsHorizontal = hbox => ({
  type: DEFORM_THUMBS_HORIZONTAL,
  payload: { hbox },
})

export const deformSelected = values => ({
  type: DEFORM_SELECTED,
  payload: { ...values },
})

export const deformSelectedBox = values => ({
  type: DEFORM_SELECTED_BOX,
  payload: { ...values },
})

export const setSelectedVcaDbl = dbl => ({
  type: SET_SELECTED_VCA_DBL,
  payload: { dbl },
})

export const setSelectedVcaValues = values => ({
  type: SET_SELECTED_VCA_VALUES,
  payload: { values },
})

export const saveSelectedVcaDeformed = options => ({
  type: SAVE_DEFORMED_SELECTED,
  payload: options,
})

export const resetSelectedVcaDeformed = () => ({
  type: RESET_DEFORMED_SELECTED,
})

export const selectVca = (props = {}) => ({ getState, fetch }) => {
  if (props.uploaded || !props._id) {
    return {
      payload: props,
      type: SELECT_VCA,
    }
  }

  const { _id, hbox, vbox, dbl, isFrame } = props
  return {
    type: 'FETCH_ONE_VCA',
    hbox,
    vbox,
    dbl,
    isFrame,
    payload: apiGet({
      fetch,
      endpoint: `vca/${_id}?isFrame=${isFrame ? true : ''}`,
      getState,
    }),
  }
}

export const setDrawingVca = vca => ({
  type: SET_DRAWING_VCA,
  payload: {
    drawingVca: vca,
  },
})

export const updateVcaDrawPoint = (index, point) => ({
  type: UPDATE_VCA_DRAW_POINT,
  payload: {
    index,
    point,
  },
})

export const normalizeDrawingVca = props => ({
  type: NORMALIZE_DRAW_VCA,
  payload: {
    ...props,
  },
})
export const saveDrawingVca = props => ({
  type: SAVE_DRAWING_VCA,
  payload: {
    ...props,
  },
})
export const smoothDrawingVca = () => ({
  type: SMOOTH_DRAW_VCA,
})
export const centerDrawingVca = () => ({
  type: CENTER_DRAW_VCA,
})
export const flipDrawingVcaHorizontally = () => ({
  type: FLIP_DRAW_VCA_HORIZONTALLY,
})

export const clearCalculatedVca = () => ({
  type: CLEAR_CALCULATED_VCA,
})

export const clearCalculatedComparedVca = () => ({
  type: CLEAR_CALCULATED_COMPARED_VCA,
})

export const setWantCalculation = value => ({
  payload: { value },
  type: SET_WANT_CALCULATION,
})

export const addOrEditHole = (values = {}) => ({
  payload: values,
  type: ADD_OR_EDIT_HOLE,
})

export const removeAllHoles = () => ({
  type: REMOVE_ALL_HOLES,
})

export const pickOneLens = ({ compared } = {}) => ({
  payload: { compared },
  type: PICK_ONE_LENS,
})

export const setHolesForEdit = ({ holes } = {}) => ({
  payload: { holes },
  type: SET_HOLES_FOR_EDIT,
})

export const saveHolesAfterEdit = () => ({
  type: SAVE_HOLES_AFTER_EDIT,
})

export const invalidateCalculation = inversed => ({
  type: INVALIDATE_CALCULATION,
  payload: { inversed },
})

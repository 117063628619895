import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Box, Row, Col } from '../..'
import { NumberField, SwitchField, SelectField } from '../../fields'
import { appTypeConfig } from '../../../../common/config'
import i18n from '../../../../common/i18n/i18n'

const { individualMenus } = appTypeConfig
const SWITCH_BUTTON_MIN_WIDTH = '10rem'
const readdistApproxOptions = [
  { value: '< 160', label: '< 160', exactValue: 38 },
  { value: '160 - 180', label: '160 - 180', exactValue: 41 },
  { value: '> 180', label: '> 180', exactValue: 44 },
]

const pantoApproxSpec = [
  { value: 8, translationKey: 'reize; Normal' },
  { value: 13, translationKey: 'reize; Strong' },
]

const cvdApproxSpec = [
  { value: 9, translationKey: 'reize; Short' },
  { value: 12, translationKey: 'reize; Normal' },
  { value: 15, translationKey: 'reize; Long' },
]

const frameBowAngleApproxSpec = [
  { value: 2, translationKey: 'reize; Normal' },
  { value: 11, translationKey: 'reize; Curved' },
]

const lateralitySpec = [
  { value: 'right', translationKey: 'reize; Right hander' },
  { value: 'left', translationKey: 'reize; Left hander' },
]

const createApproxOptions = (spec, t) =>
  spec.map(o => ({
    value: o.translationKey,
    label: t(o.translationKey),
    exactValue: o.value,
  }))

const FaceFormAngle = props => (
  <NumberField
    label={i18n.t('face form angle')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const Cvd = props => (
  <NumberField
    label={i18n.t('reize; Cvd')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const Panto = props => (
  <NumberField
    label={i18n.t('pantoscopic tilt')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const Readdist = props => (
  <NumberField
    label={i18n.t('reize; Reading distance')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const Fvs = props => (
  <NumberField
    label={i18n.t('reize; Far vision sensitivity')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const Nvs = props => (
  <NumberField
    label={i18n.t('reize; Near vision sensitivity')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const ContrastSv = props => (
  <NumberField
    label={i18n.t('reize; Contrast sensitivity')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const DesignType = props => (
  <NumberField
    label={i18n.t('reize; Design type')}
    errorsStyle={{ position: 'relative' }}
    noMaxWidth
    {...props}
  />
)

const isFilled = field => !!field.value || field.value === 0

const Or = props => (
  <Col alignSelf="flex-start" style={{ textAlign: 'center' }} {...props}>
    <div style={{ paddingTop: '2.7rem', fontSize: '1.4rem' }}>or</div>
  </Col>
)

const LineWithApprox = (
  { children } //eslint-disable-line
) => (
  <Row>
    <Col width="15rem">{children && children[0]}</Col>
    <Or width="3rem" />
    <Col grow={1}>{children && children[1]}</Col>
  </Row>
)

const enhanceFields = (fields, menuConf) => {
  Object.keys(menuConf).forEach(key => {
    const fieldConf = menuConf[key]
    const isRequired = typeof fieldConf === 'object' ? fieldConf.isRequired : fieldConf === true

    const field = fields[key]
    if (field) {
      field.required = isRequired
    }
  })
}

const isFieldVisible = fieldConf => typeof fieldConf !== 'undefined'

class IndividualSection extends React.Component {
  constructor(props) {
    super(props)
    const { fields } = props
    this.state = {
      // used only for validation after individual menu change
      inMenuKey: fields.individualMenu && fields.individualMenu.value,
    }
  }

  componentDidUpdate() {
    const { inMenuKey } = this.state
    const { fields } = this.props
    const newInMenuKey = fields.individualMenu && fields.individualMenu.value
    if (inMenuKey !== newInMenuKey) {
      this.setState({ inMenuKey: newInMenuKey }) // eslint-disable-line
      if (newInMenuKey) {
        const menuConf = individualMenus[newInMenuKey]
        Object.keys(menuConf).forEach(fieldKey => {
          const field = fields[fieldKey]
          if (field.errors && field.errors.length > 1) {
            field.onBlur()
          }
        })
      }
    }
  }

  render() {
    const { fields, lensL, lensR, t } = this.props
    if (
      !(
        (lensR && fields.lensR.value && lensR.individualMenu) ||
        (lensL && fields.lensL.value && lensL.individualMenu)
      )
    ) {
      return null
    }

    const menuConf = individualMenus[fields.individualMenu && fields.individualMenu.value]
    if (!menuConf) return null

    enhanceFields(fields, menuConf)

    let proglenOptions = []
    if (menuConf.proglen && menuConf.proglen.options) {
      const configOptions = menuConf.proglen.options
      proglenOptions = configOptions.map(o => {
        if (!o) return { label: t('AUTO'), value: '' }
        return { label: o, value: o }
      })
      const proglenValue = fields.proglen && fields.proglen.value
      if (proglenValue && !configOptions.includes(proglenValue) && configOptions.includes('')) {
        fields.proglen.onChange({ value: '' })
      }
    }
    const itemsCount = Object.keys(menuConf || {}).length
    const columnWidth = [4, 5].includes(itemsCount) ? '25%' : '33%'

    const pantoApproxOptions = createApproxOptions(pantoApproxSpec, t)
    const cvdApproxOptions = createApproxOptions(cvdApproxSpec, t)
    const frameBowAngleApproxOptions = createApproxOptions(frameBowAngleApproxSpec, t)
    const lateralityOptions = lateralitySpec.map(({ value, translationKey }) => ({
      value,
      label: t(translationKey),
    }))

    return (
      <Box>
        <Box.Header>
          <Box.Title>{t('individual data')}</Box.Title>
        </Box.Header>
        <Box.Content>
          {/* FACEFORMANGLE WITH APPROX */}
          {isFieldVisible(menuConf.frameBowAngle) && isFieldVisible(menuConf.frameBowAngleApprox) && (
            <LineWithApprox>
              <FaceFormAngle
                disabled={isFilled(fields.frameBowAngleApprox)}
                noMaxWidth
                {...fields.frameBowAngle}
                value={!isFilled(fields.frameBowAngleApprox) && fields.frameBowAngle.value}
              />
              <SwitchField
                label={t('reize; Frame bow angle approximated')}
                options={frameBowAngleApproxOptions}
                tooltip={t('reize; Frame bow angle approximated DESCRIPTION')}
                tooltipDown
                // disabled={isFilled(fields.frameBowAngle)}
                buttonMinWidth={SWITCH_BUTTON_MIN_WIDTH}
                {...fields.frameBowAngleApprox}
                onChange={e => {
                  fields.frameBowAngleApprox.onChange(e)
                  fields.frameBowAngle.onChange({ value: e.value && e.exactValue })
                  setTimeout(() => fields.frameBowAngle.onBlur(), 1)
                }}
              />
            </LineWithApprox>
          )}
          {/* CVD WITH APPROX */}
          {isFieldVisible(menuConf.cvd) && isFieldVisible(menuConf.cvdApprox) && (
            <LineWithApprox>
              <Cvd
                disabled={isFilled(fields.cvdApprox)}
                noMaxWidth
                {...fields.cvd}
                value={!isFilled(fields.cvdApprox) && fields.cvd.value}
              />
              <SwitchField
                label={t('reize; Cvd approximated')}
                options={cvdApproxOptions}
                tooltip={t('reize; Cvd approximated DESCRIPTION')}
                tooltipDown
                // disabled={isFilled(fields.cvd)}
                buttonMinWidth={SWITCH_BUTTON_MIN_WIDTH}
                {...fields.cvdApprox}
                onChange={e => {
                  fields.cvdApprox.onChange(e)
                  fields.cvd.onChange({ value: e.value && e.exactValue })
                  setTimeout(() => fields.cvd.onBlur(), 1)
                }}
              />
            </LineWithApprox>
          )}
          {/* PANTO WITH APPROX */}
          {isFieldVisible(menuConf.panto) && isFieldVisible(menuConf.pantoApprox) && (
            <LineWithApprox>
              <Panto
                disabled={isFilled(fields.pantoApprox)}
                noMaxWidth
                {...fields.panto}
                value={!isFilled(fields.pantoApprox) && fields.panto.value}
              />
              <SwitchField
                label={t('reize; Panto approximated')}
                options={pantoApproxOptions}
                tooltip={t('reize; Panto approximated DESCRIPTION')}
                tooltipDown
                buttonMinWidth={SWITCH_BUTTON_MIN_WIDTH}
                {...fields.pantoApprox}
                // disabled={isFilled(fields.panto)}
                onChange={e => {
                  fields.pantoApprox.onChange(e)
                  fields.panto.onChange({ value: e.value && e.exactValue })
                  setTimeout(() => fields.panto.onBlur(), 1)
                }}
              />
            </LineWithApprox>
          )}
          {/* READDIST WITH APPROX */}
          {isFieldVisible(menuConf.readdist) && isFieldVisible(menuConf.readdistApprox) && (
            <LineWithApprox>
              <Readdist
                disabled={isFilled(fields.readdistApprox)}
                noMaxWidth
                {...fields.readdist}
                value={!isFilled(fields.readdistApprox) && fields.readdist.value}
              />
              <SwitchField
                label={t('reize; Body height of wearer')}
                options={readdistApproxOptions}
                tooltip={t('reize; Body height of wearer DESCRIPTION')}
                tooltipDown
                buttonMinWidth={SWITCH_BUTTON_MIN_WIDTH}
                {...fields.readdistApprox}
                // disabled={isFilled(fields.readdist)}
                onChange={e => {
                  fields.readdistApprox.onChange(e)
                  fields.readdist.onChange({ value: e.value && e.exactValue })
                  setTimeout(() => fields.readdist.onBlur(), 1)
                }}
              />
            </LineWithApprox>
          )}
          <Row alignItems="flex-start">
            {/* FACEFORMANGLE */}
            {isFieldVisible(menuConf.frameBowAngle) &&
              !isFieldVisible(menuConf.frameBowAngleApprox) && (
                <Col width={columnWidth}>
                  <FaceFormAngle {...fields.frameBowAngle} />
                </Col>
            )}

            {/* CVD */}
            {isFieldVisible(menuConf.cvd) && !isFieldVisible(menuConf.cvdApprox) && (
              <Col width={columnWidth}>
                <Cvd {...fields.cvd} />
              </Col>
            )}

            {/* PANTO */}
            {isFieldVisible(menuConf.panto) && !isFieldVisible(menuConf.pantoApprox) && (
              <Col width={columnWidth}>
                <Panto {...fields.panto} />
              </Col>
            )}

            {/* READDIST */}
            {isFieldVisible(menuConf.readdist) && !isFieldVisible(menuConf.readdistApprox) && (
              <Col width={columnWidth}>
                <Readdist {...fields.readdist} />
              </Col>
            )}
          </Row>
          <Row alignItems="flex-start">
            {/* LATERALITY */}
            {isFieldVisible(menuConf.laterality) && (
              <Col>
                <SwitchField
                  label={t('reize; Laterality')}
                  options={lateralityOptions}
                  {...fields.laterality}
                />
              </Col>
            )}
            {/* PROGLEN */}
            {isFieldVisible(menuConf.proglen) && (
              <Col width="50%">
                <SelectField
                  label={t('reize; Progression length')}
                  options={proglenOptions}
                  {...fields.proglen}
                />
              </Col>
            )}
            <Col />
          </Row>
          <Row alignItems="flex-start">
            {/* FAR VISION SENSITIVITY */}
            {isFieldVisible(menuConf.fvs) && (
              <Col width={columnWidth}>
                <Fvs {...fields.fvs} />
              </Col>
            )}
            {/* NEAR VISION SENSITIVITY */}
            {isFieldVisible(menuConf.nvs) && (
              <Col width={columnWidth}>
                <Nvs {...fields.nvs} />
              </Col>
            )}
            {/* CONTRAST SENSITIVITY */}
            {isFieldVisible(menuConf.contrastSv) && (
              <Col width={columnWidth}>
                <ContrastSv {...fields.contrastSv} />
              </Col>
            )}
            {/* DESIGN TYPE */}
            {isFieldVisible(menuConf.designType) && (
              <Col width={columnWidth}>
                <DesignType {...fields.designType} />
              </Col>
            )}
          </Row>
        </Box.Content>
      </Box>
    )
  }
}

IndividualSection.defaultProps = {
  lensR: {},
  lensL: {},
}

IndividualSection.propTypes = {
  fields: PropTypes.object.isRequired,
  lensR: PropTypes.object,
  lensL: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    ({ catalog }) => ({
      lensR: catalog.currentLensR,
      lensL: catalog.currentLensL,
    }),
    {},
  ),
  withTranslation(),
)

export default enhance(IndividualSection)

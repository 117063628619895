import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import identity2Config from './identity2'
import identityLightConfig from './identityLight'
import { createOrUpdateOrder } from '../../common/orders/actions'

const configMap = {
  IDENTITY_2: identity2Config,
  IDENTITY_LIGHT: identityLightConfig,
}

const defaultOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]

const getAnswerValue = ({ value }) => value ? 1 : 0

const validateAnswers = ({ questions, answers, setAnswers }) => {
  const invalidAnswersToSet = questions
    .filter(({ name }) => ![true, false].includes(answers[name]?.value))
    .reduce((result, { name }) => {
      result[name] = {
        ...answers[name],
        errors: [{ isInvalid: true }],
      }
      return result
    }, {})

  const isValid = Object.keys(invalidAnswersToSet).length === 0
  if (!isValid) setAnswers({ ...answers, ...invalidAnswersToSet })

  return isValid
}

const getResult = ({
  questions,
  answers,
  setAnswers,
  evaluationMap,
  defaultResult,
}) => {
  const isFormValid = validateAnswers({ questions, answers, setAnswers })

  if (!isFormValid) {
    throw new Error({ message: 'Please answer all questions' })
  }

  // answers mapped to ones and zeros. Eg, [1, 1, 0, 0, 0]
  const answersMatrix = questions.map(({ name }) =>
    getAnswerValue(answers[name]),
  )

  // find matching result matrix
  const resultKey = Object.keys(evaluationMap).find(key => {
    const matchingAnswers = evaluationMap[key]

    return matchingAnswers.some(resultMatrix =>
      resultMatrix.every((value, index) => value === answersMatrix[index]),
    )
  })

  // if no matching result found, return default result
  return resultKey || defaultResult
}

const useSurvey = surveyVariant => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [answers, setAnswers] = useState({})
  const isInvalid = Object.values(answers).some(({ errors }) => errors)

  const surveyConfig = configMap[surveyVariant]
  if (!surveyConfig) {
    throw new Error(`Unknown survey type: ${surveyVariant}`)
  }

  const { questions, evaluationMap, defaultResult, title } = surveyConfig

  const isFilled = Object.values(questions).every(
    ({ name }) => answers[name]?.value !== undefined,
  )

  // create array of questions that is mapped to UI components
  const questionsArray = questions.map(
    ({ name, text, options = defaultOptions, ...rest }) => ({
      name,
      questionText: text,
      fieldProps: {
        value: answers[name]?.value,
        options: options.map(({ value, label }) => ({
          value,
          label: t(label),
        })),
        ...rest,
        onChange: ({ value }) =>
          setAnswers({ ...answers, [name]: { value, errors: null } }),
        errors: answers[name]?.errors,
      },
    }),
  )

  const submit = async data => {
    // get result based on answers
    // or throw error if form is invalid
    const result = getResult({
      questions,
      answers,
      setAnswers,
      evaluationMap,
      defaultResult,
    })

    const order = {
      reference: `${t(`jaikudo: ${surveyVariant} default reference`)} ${
        data?.clientName
      }`,
      orderType: 'DIAMETER_ONLY',
      rightLensEnabled: true,
      leftLensEnabled: true,
      survey: {
        result,
        variant: surveyVariant,
      },
      ...data,
    }

    const response = await dispatch(
      createOrUpdateOrder({ order, isConcept: true }),
    ).meta.action.payload

    return response?.data?.order?._id
  }

  return {
    questions: questionsArray,
    title,
    isInvalid,
    isFilled,
    submit,
  }
}

export default useSurvey

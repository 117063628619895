import React from 'react'
import styled, { css } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PopupPresentational from '../components/popups/PopupPresentational'
import { SwitchField } from '../components/fields'
import { Text, Button, Row, Col, Gap, T } from '../components'
import useSurvey from './useSurvey'
import SaveSurveyPopup from './SaveSurveyPopup'
import { Link } from '../components/Link'
import { withNotifs } from '../components/hoc'

const TableWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    border: 2px solid ${colors.tableBorderColor};
    border-radius: 6px;
  `}
`

const Table = styled.table`
  ${({ theme: { colors } }) => css`
    border-collapse: collapse;
    width: 100%;
    tr {
      &:nth-child(odd) {
        background-color: ${colors.tableOddRowBackground};
      }
      border-bottom: 2px solid ${colors.tableBorderColor};
      &:last-child {
        border-bottom: none;
      }
    }
    td {
      padding: 1.2rem 1.6rem;
      &:last-child {
        padding-left: 2.5rem;
      }
    }
  `}
`

const SurveyPopup = ({
  close,
  surveyType,
  title: popupTitle,
  args,
  notifs,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { questions, title, isFilled, submit } = useSurvey(surveyType)

  const onSubmit = async (data, { shouldOpenOrder } = {}) => {
    try {
      const orderId = await submit(data)

      args?.afterSave()

      notifs.success({ title: t('the concept was saved') })

      if (shouldOpenOrder && orderId) {
        setTimeout(() => {
          navigate(`${Link.CREATE_ORDER_STEP_1}?_id=${orderId}`)
        }, 200)
      }
      close()
      return orderId
    } catch (error) {
      notifs.error({ title: t('saving failed') })
      console.notifyError(error, { message: 'Survey submit error' })
      return null
    }
  }

  const Footer = () => (
    <Col justifyContent="center" alignItems="center">
      <SaveSurveyPopup onSubmit={onSubmit}>
        {({ open }) => (
          <Button
            primary
            onClick={open}
            large
            rightIcon="CustomDoubleArrow"
            disabled={!isFilled}
          >
            <T>save</T>
          </Button>
        )}
      </SaveSurveyPopup>
      <Gap gap="20px" />
    </Col>
  )

  return (
    <PopupPresentational
      title={t(`jaikudo; ${popupTitle}`)}
      close={close}
      displayChildrenInMessage={false}
      showControlButtons={false}
      FooterComponent={Footer}
    >
      <Gap gap="2rem" />
      <Text bold size="2rem">
        {t(`jaikudo; ${title}`)}
      </Text>
      <Gap gap="2rem" />

      <div>
        <TableWrapper>
          <Table>
            {questions.map(({ fieldProps, questionText }) => (
              <tr>
                <td>
                  <Text big>{t(`jaikudo; ${questionText}`)}</Text>
                </td>
                <td>
                  <SwitchField evenlySpaced {...fieldProps} />
                </td>
              </tr>
            ))}
          </Table>
        </TableWrapper>
      </div>
      <Gap gap="2rem" />
    </PopupPresentational>
  )
}

export default withNotifs(SurveyPopup)

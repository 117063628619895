import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io'
import { Text, Row, Col } from '..'
import useIsSticky from '../useIsSticky'
import useOnResizeAndScroll from '../useOnResizeAndScroll'
import Loading, { WrappedLoading } from '../Loading'

const TableWrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.tableBorderColor};
  border-radius: 6px;
  /* overflow: hidden; */
  width: 100%;
`

const StyledTable = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
`

const Header = styled.div`
  display: table-header-group;
`

const Body = styled.div`
  display: table-row-group;
  position: relative;
`
// const LoaderOverlay = styled.div`
//   position: absolute;
//   z-index: 1;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(255, 255, 255, 0.7);
//   padding-top: 10rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `
// const LoadingWrapper = styled.div`
//   width: 100%;
// `

const TableRow = styled(Row)`
  padding: 1rem 2rem;
  display: table-row;
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.tableOddRowBackground};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryVeryLight};
    background-color: #f0f0f0;
  }
`

const HeadingInnerWrapper = styled.div`
  word-wrap: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* background: white; */
  padding: 1.1rem;
  min-height: 4.6rem;
  /* background: rgba(255,255,255, 0.1);
  backdrop-filter: blur(16px); */
  width: max-content;
`

const TableHeading = styled.th`
  ${({ headerWidth, headerHeight, isSticky, disabled }) => css`
    ${disabled && 'pointer-events: none;'}
    display: table-cell;
    cursor: pointer;
    user-select: none;
    position: sticky;
    font-weight: 400;
    top: 0;
    /* background-color: white; */
    z-index: 2;
    box-shadow: 0px 2px 0px ${({ theme }) => theme.colors.tableHeaderBorderColor};

    &::before {
      content: '';
      /* display: table-cell; */
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -2px;
      left: 0;
      z-index: -2;
      transition: 0.3s;
      /* box-shadow: 0px 1px 10px ${({ isSticky }) =>
    isSticky ? 'rgba(0,0,0,1)' : 'transparent'}; */
    }

    vertical-align: middle;
    & * {
      vertical-align: middle;
    }

    &:first-child {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        width: ${headerWidth}px;
        height: 42px;
        height: ${headerHeight + (isSticky ? 1 : 0)}px;
        bottom: 0;
        left: 0;
        z-index: -2;
        background: rgba(255, 255, 255, 1);
        border-radius: 6px 6px 0 0;

        @supports (backdrop-filter: none) or (-webkit-backdrop-filter: none) {
          background: rgba(255, 255, 255, 0.6);
          backdrop-filter: blur(9px);
        }
      }

      ${HeadingInnerWrapper} {
        border-radius: 6px 0 0 0;
      }
    }
    &:last-child {
      ${HeadingInnerWrapper} {
        border-radius: 0 6px 0 0;
      }
    }
  `}
`

const TableCell = styled(Col)`
  display: table-cell;
  padding: 0.5rem 1rem;
  vertical-align: middle;
  position: relative;
`

const SortIconWrapper = styled.div`
  /* display: inline-block; */
  margin-left: 0.5rem;
  /* height: 1rem; */
  /* top: -.7rem; */
  top: 0.2rem;
  position: relative;
  opacity: ${({ opacity }) => opacity};
  transition: 0.4s;

  & * {
    display: block;
    font-weight: 700;
    &:first-child {
      margin-bottom: -0.8rem;
    }
  }
`

const TableHeader = ({ columns, recalcHeaderWidth, disabled }) => {
  const { isSticky, ref } = useIsSticky()

  const recalcWidth = useRef(recalcHeaderWidth)

  const headerRef = useRef()
  const [headerWidth, setHeaderWidth] = useState(null)
  const [headerHeight, setHeaderHeight] = useState(42)

  const resizeCallback = useCallback(() => {
    const { width, height } = headerRef?.current?.getBoundingClientRect?.()
    setHeaderWidth(width)
    setHeaderHeight(height)
  }, [])

  useOnResizeAndScroll(resizeCallback)

  // this is for fullWidth table switch
  if (recalcWidth?.current !== recalcHeaderWidth) {
    setHeaderWidth(null)
    setTimeout(() => {
      resizeCallback()
    }, 350)
  }
  recalcWidth.current = recalcHeaderWidth

  return (
    <Header name="order-table-header" ref={headerRef}>
      <tr>
      {columns.map(
        ({ colProps, headerColProps, headerText, renderHeader, onClick, sortState }, key) => (
          <TableHeading
            ref={ref}
            key={key}
            disabled={disabled}
            onClick={onClick}
            {...colProps}
            {...headerColProps}
            isSticky={isSticky}
            headerWidth={headerWidth}
            headerHeight={headerHeight}
          >
            <HeadingInnerWrapper>
              <div>
                {renderHeader
                  ? renderHeader()
                  : (
                    <Text opacity={disabled ? 0.4 : 1} bold={sortState !== 0 && onClick}>
                      {headerText}
                    </Text>
                  )}
              </div>
              {onClick && (
                <SortIconWrapper opacity={disabled ? 0.4 : 1}>
                  <IoIosArrowUp color={sortState === 1 ? '#0265ab' : '#BDBDBD'} />
                  <IoIosArrowDown color={sortState === -1 ? '#0265ab' : '#BDBDBD'} />
                </SortIconWrapper>
              )}
            </HeadingInnerWrapper>
          </TableHeading>
        ),
      )}
      </tr>
    </Header>
  )
}

const Table = ({ data, columns, createRowClickHandler, recalcHeaderWidth, isLoading }) => {
  columns = columns.filter(c => c)

  return (
    <TableWrapper name="order-table">
      <StyledTable>
        <TableHeader columns={columns} recalcHeaderWidth={recalcHeaderWidth} disabled={isLoading} />

        <tr>
          <td colSpan="100%" height="5px">
            {isLoading && <Loading type="rainbow" />}
          </td>
        </tr>

        <Body name="order-table-body">
          {data.map((row, key) => (
            <TableRow key={`${key}${row._id}`} onClick={createRowClickHandler?.(row)}>
              {columns.map(({ colProps, column, renderCol }, key) => (
                <TableCell key={key} {...colProps}>
                  {renderCol
                    ? renderCol(row)
                    : typeof column !== 'undefined' && <Text>{row[column]}</Text>}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {/* {isLoading && (
            <LoaderOverlay>
              <Loading type="puff" />
            </LoaderOverlay>
          )} */}
        </Body>
      </StyledTable>
    </TableWrapper>
  )
}

Table.defaultProps = {
  data: [],
  sortBy: '',
  sortAsc: true,
  sortHandler: () => {},
  sortableColumns: [],
  createRowClickHandler: undefined,
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  sortBy: PropTypes.string,
  sortAsc: PropTypes.bool,
  sortHandler: PropTypes.func,
  createRowClickHandler: PropTypes.func,
  sortableColumns: PropTypes.array,
}

export default Table

const frequencyOptions = [
  { value: true, label: 'Often' },
  { value: false, label: 'Rarely' },
]

export const questions = [
  {
    name: 'usedProgressiveLenses',
    text: 'Has your client worn progressive lenses before?',
  },
  {
    name: 'further',
    text: 'Further',
    options: frequencyOptions,
  },
  {
    name: 'intermediate',
    text: 'Intermediate',
    options: frequencyOptions,
  },
  {
    name: 'closer',
    text: 'Closer',
    options: frequencyOptions,
  },
]

const defaultResult = 'Short'

export default {
  questions,
  evaluationMap: [],
  defaultResult,
  title: 'Select frequency of use of vision areas',
}

import styled from 'styled-components'

const CatalogGrid = styled.div.attrs({ 'data-scroll-lock-ignore': true })`
  display: grid;
  grid-template-columns: 20rem 1fr;
  width: 100%;
  column-gap: 1rem;
  @media (min-width: 1200px) {
    column-gap: 2rem;
    grid-template-columns: 28rem 1fr;
  }
`

export default CatalogGrid

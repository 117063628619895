import React from 'react'
import PropTypes from 'prop-types'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { withTranslation } from 'react-i18next'
import styled, { keyframes, css } from 'styled-components'
import { nanoid } from 'nanoid'
import Button from '../Button'
import Gap from '../Gap'
import Popup from './Popup'
import CloseIcon from '../CloseIcon'

const bounceInKeyframes = keyframes`
 0%{
    opacity: 0;
    transform:  translate(-50%,-50%) scale(0.3);
  }

  70%{
    opacity: 0.9;
    transform: translate(-50%,-50%) scale(1.02);
  }
  90%{
    opacity: 1;
    transform:  translate(-50%,-50%) scale(0.99);
  }
  100%{
    opacity: 1;
    transform: translate(-50%,-50%) scale(1);
  }
`
const overlayInKeyframes = keyframes`
 0%{
  background: rgba(0, 0, 0, 0);
  opacity: 0;
  }
  50%{
    background: rgba(0, 0, 0, 0.5);
    /* opacity: 1; */
  }
  100%{
    background: rgba(0, 0, 0, 0.5);
    opacity: 1;

  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  position: fixed;
  transition: 1s;
  z-index: 1100;
  backdrop-filter: blur(8px);
  opacity: 1;

  animation-name: ${overlayInKeyframes};
  animation-duration: 350ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`

const Wrapper = styled.div`
  overflow: hidden;
  min-width: ${props => props.width || (props.type === 'small' ? 60 : 102)}rem;
  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  min-height: 10rem;
  height: ${props => props.height};
  max-height: 95vh;
  background: white;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  z-index: 100;
  border-radius: 0.8rem;
  /* overflow: auto; */
  box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.5);

  ${props =>
    !props.disableAnimation &&
    css`
      transition: 1s;
      transform: translate(-50%, -50%);
      opacity: 0;
      animation-name: ${bounceInKeyframes};
      animation-duration: 500ms;
      animation-timing-function: ease-out;
      animation-fill-mode: forwards;
    `};
`

Wrapper.displayName = 'Wrapper'

const Content = styled.div`
  overflow: ${props => props.contentOverflow || 'auto'};
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 2rem;
  flex-grow: ${props => props.maxContent && 1000};
`
Content.displayName = 'Content'

const Header = styled.div`
  background: ${({ theme }) => theme.colors.popupHeader};
  border-bottom: 1px solid ${({ theme }) => theme.colors.popupHeaderBorder};
  color: ${({ theme }) => theme.colors.popupTitle};
  text-transform: uppercase;
  letter-spacing: 3px;
  padding: 1.5rem 3rem;
  text-align: center;
  font-size: 1.6rem;
  position: relative;
  font-weight: bold;
  border-radius: 0.8rem 0.8rem 0 0;
`

const Message = styled.div`
  padding: 2rem;
  text-align: center;
  font-size: 1.7rem;
  line-height: 3rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`
Message.displayName = 'Message'

const ButtonsWrapper = styled.div`
  padding: 1rem 0 2rem;
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  /* min-height: 10rem; */
  justify-content: center;
  flex-shrink: 0;
`

let lockedPopups = []

const enhancedDisableBodyScroll = (targetElement, id) => {
  // TODO: this will mess up tooltips position -- solve that first
  // document.body.style.top = document.body.style.top || `-${window.scrollY}px`
  disableBodyScroll(targetElement, {
    reserveScrollBarGap: true,
    // enable scroll on touch devices for elements with data-scroll-lock-ignore attribute
    // it works a bit magically, but it is ok for now
    allowTouchMove: el => {
      while (el && el !== document.body) {
        if (el.getAttribute('data-scroll-lock-ignore') !== null) {
          return true
        }
        el = el.parentElement
      }
      return false
    },
  })
  // this was breaking the scroll on mobile
  // scroll didn't work after closing the popup
  // but it seems ok without it
  // document.body.style.position = 'fixed'
  // document.body.style.overflow = ''
  lockedPopups.push(id)
}

const enhancedEnableBodyScroll = (targetElement, id) => {
  lockedPopups = lockedPopups.filter(el => el !== id)
  // console.log('lockedPopups', lockedPopups)
  // if (lockedPopups.length === 0) {
  //   document.body.style.top = ''
  //   document.body.style.position = ''
  // }
  enableBodyScroll(targetElement)
}
class PopupPresentational extends React.Component {
  state = {
    id: nanoid(),
  }

  targetRef = React.createRef()

  componentDidMount() {
    // this popup became visible --> add it to history
    const { state: currentHistoryState = {} } = window.history
    window.history.pushState({
      ...currentHistoryState,
      openPopups: {
        ...(currentHistoryState.openPopups || null),
        [this.state.id]: true, // here
      },
    })

    window.addEventListener('popstate', this.handlePopState)

    this.targetElement = this.targetRef.current
    enhancedDisableBodyScroll(this.targetElement, this.state.id)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState)
    enhancedEnableBodyScroll(this.targetElement, this.state.id)
    // clearAllBodyScrollLocks()
  }

  // usually back event
  handlePopState = event => {
    // if in new state is not our popup id then
    if (!event.state?.openPopups?.[this.state.id]) {
      // if we have confirmation of closing this popup then show it
      if (this.props.cancelPopup) {
        // but first add current state (once again with our popup id)
        // we will go to this state once the confirm cancel popup will be closed
        window.history.pushState({
          ...event.state,
          openPopups: {
            ...(event.state?.openPopups || null),
            [this.state.id]: true,
          },
        })
        // finally open confirm popup
        this.openCancelPopup?.()
      } else {
        // just close it
        this.props.close()
      }
    }
  }

  render() {
    const {
      title,
      text,
      close,
      children,
      type,
      okText,
      cancelText,
      cancelPopup,
      cancelPopupTitle,
      cancelPopupMessage,
      cancelPopupOkText,
      cancelPopupCancelText,
      okDisabled,
      cancelDisabled,
      showControlButtons,
      hideOk,
      hideCancel,
      displayChildrenInMessage,
      displayTextInMessage,
      style,
      contentStyle,
      width,
      minWidth,
      maxWidth,
      height,
      maxContent,
      FooterComponent,
      HeaderComponent,
      disableHistoryManipulation,
      t,
      disableAnimation,
      contentOverflow,
      contentRef,
      injectPropsToContent,
      largeButtons,
      renderBeforeBody,
    } = this.props

    const closeOk = e => {
      e.preventDefault()
      e.stopPropagation()
      if (!okDisabled) {
        // send to handler
        close({ ok: true })
        if (!disableHistoryManipulation) {
          // we have to remove this popup id from history
          window.history.back()
        }
      }
    }

    return (
      <Popup
        title={cancelPopupTitle || t('Are you sure?')}
        text={cancelPopupMessage || t('Do you want to discard changes you have made?')}
        closeOnOutsideClick
        closeOnEsc
        onClose={opts => {
          // if we confirm closing main popup via the confirmation cancel popup
          if (opts.ok) {
            // close this directly, since calling event would trigger the confirm popup again
            close()
            // go one step back - confirmation popup already did, so this is second event
            // - to clean up main popup id
            window.history.back()
          }
        }}
        okText={cancelPopupOkText || t('delete')}
        closeText={cancelPopupCancelText || t('cancel')}
        // disableBodyScroll={() => {
        //   enhancedDisableBodyScroll(this.targetElement, this.state.id)
        // }}
        // enableBodyScroll={() => {
        //   enhancedEnableBodyScroll(this.targetElement, this.state.id)
        // }}
      >
        {({ open: openCancelPopup }) => {
          this.openCancelPopup = openCancelPopup // ugly, but just save it for later use

          const triggerPopupCloseViaHistory = e => {
            e?.preventDefault()
            e?.stopPropagation()

            window.history.back()
          }

          // TODO: you can add okClose or forceClose once needed
          const contentInjectableProps = {
            cancelClose: triggerPopupCloseViaHistory,
          }

          return (
            <>
              <Overlay onClick={triggerPopupCloseViaHistory}>
                {renderBeforeBody}
                <Wrapper
                  disableAnimation={disableAnimation}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  type={type}
                  style={style}
                  width={width}
                  minWidth={minWidth}
                  maxWidth={maxWidth}
                  height={height}
                  ref={this.targetRef}
                >
                  <Header>
                    {title}
                    <CloseIcon onClick={triggerPopupCloseViaHistory} />
                  </Header>
                  {HeaderComponent && <HeaderComponent />}
                  <Content
                    contentOverflow={contentOverflow}
                    style={contentStyle}
                    maxContent={maxContent}
                    ref={contentRef}
                  >
                    {!injectPropsToContent && (
                      <>
                        {text && displayTextInMessage && <Message>{text}</Message>}
                        {text && !displayTextInMessage && text}
                        {children && displayChildrenInMessage && <Message>{children}</Message>}
                        {children && !displayChildrenInMessage && children}
                      </>
                    )}
                    {injectPropsToContent && (
                      <>
                        {text && text(contentInjectableProps)}
                        {children && children(contentInjectableProps)}
                      </>
                    )}
                  </Content>
                  {showControlButtons && (
                    <ButtonsWrapper>
                      {!hideOk && (
                        <Button
                          large={largeButtons}
                          disabled={okDisabled}
                          primary
                          onClick={closeOk}
                        >
                          {okText}
                        </Button>
                      )}
                      {!hideOk && !hideCancel && <Gap />}
                      {!hideCancel && (
                        <Button
                          large={largeButtons}
                          disabled={cancelDisabled}
                          onClick={triggerPopupCloseViaHistory}
                        >
                          {cancelText || t('cancel')}
                        </Button>
                      )}
                    </ButtonsWrapper>
                  )}
                  {FooterComponent && <FooterComponent />}
                </Wrapper>
              </Overlay>
            </>
          )
        }}
      </Popup>
    )
  }
}

PopupPresentational.defaultProps = {
  title: null,
  type: 'small',
  okText: 'OK',
  cancelText: '',
  text: null,
  children: null,
  okDisabled: false,
  hideOk: false,
  hideCancel: false,
  showControlButtons: true,
  displayChildrenInMessage: true,
  displayTextInMessage: true,
  style: null,
  contentStyle: null,
}

PopupPresentational.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string,
  close: PropTypes.func.isRequired,
  type: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  okDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  showControlButtons: PropTypes.bool,
  displayChildrenInMessage: PropTypes.bool,
  displayTextInMessage: PropTypes.bool,
  hideOk: PropTypes.bool,
  hideCancel: PropTypes.bool,
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(PopupPresentational)

import React, { Component, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTranslation, useTranslation } from 'react-i18next'
import styled, { css, withTheme, useTheme } from 'styled-components'
import Deformator from '../vca/Deformator'
import Visualiser from '../vca/Visualiser'
import PopupPresentational from './PopupPresentational'
import Popup from './Popup'
import { NumberField, TextField } from '../fields'
import { Row, Col, Text, Gap, Icon } from '..'
import Loading from '../Loading'

import {
  deformThumbsVertical,
  deformThumbsHorizontal,
  selectVca,
  deleteMyShape,
} from '../../../common/vca/actions'

const getItemShapeStyle = ({ isPicked, theme }) => {
  if (isPicked) {
    return {
      shape: {
        fillStyle: 'none',
        lineWidth: 0.7,
        color: theme.colors.primary,
      },
      title: {
        fillStyle: theme.colors.primary,
      },
    }
  }
  return {
    shape: {
      fillStyle: theme.colors.gray2,
      lineWidth: 0.5,
      color: theme.colors.grayDark,
    },
    title: {
      fillStyle: theme.colors.grayDark,
    },
  }
}

const ListItem = React.memo(
  ({ vca, onClick, isPicked, deleteMyShape, popupType }) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const onItemClick = useMemo(() => () => {
      onClick(vca._id)
    }, [vca._id, onClick])

    return (
      <ThumbVisualiserWrapper key={vca._id}>
        <ThumbVisualiser
          onClick={onItemClick}
          key={vca._id}
          {...vca}
          rightOnly
          width={238}
          height={230}
          hideToggleButton
          forceRealSize // TODO: tlacitko na to do  reduxu
          title={vca.name}
          drawStyles={getItemShapeStyle({ isPicked, theme })}
          touchScrollEnabled
        />
        {popupType !== 'standardShapes' && popupType !== 'collection' && (
          <IconsWrapper>
            <Popup
              title={t('Delete shape from favorites?')}
              text={t('Do you really want to delete selected shape?')}
              closeOnOutsideClick
              closeOnEsc
              onClose={opts => {
                if (opts.ok) {
                  deleteMyShape(vca._id)
                }
              }}
              okText={t('delete')}
              closeText={t('cancel')}
            >
              {({ open }) => (
                <>
                  <OneIconWrapper onClick={open}>
                    <Icon name="IosTrash" size={24} />
                  </OneIconWrapper>
                </>
              )}
            </Popup>
          </IconsWrapper>
        )}
      </ThumbVisualiserWrapper>
    )
  },
)

const ThumbsWrapper = styled.div`
  overflow: auto;
  max-height: 40rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
`

const ThumbVisualiser = styled(Visualiser)`
  cursor: pointer;
`

const IconsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  opacity: 0;
  transition: 0.3s;
`

const ThumbVisualiserWrapper = styled.div`
  cursor: pointer;

  position: relative;
  &:hover {
    ${IconsWrapper} {
      opacity: 1;
    }
  }
`

const OneIconWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    cursor: pointer;
    color: ${colors.red};
    transition: 0.3s;

    &:hover {
      color: ${colors.redLight};
    }
  `}
`

const INITIAL_LIMIT = 60
const LIMIT_STEP = 60

class StandardShapeLibPopup extends Component {
  state = {
    pickedShapeId: null,
    searchInputValue: '',
    limit: INITIAL_LIMIT,
  }

  getShapeStyle = isPicked => {
    const { theme } = this.props
    if (isPicked) {
      return {
        shape: {
          fillStyle: 'none',
          lineWidth: 0.7,
          color: theme.colors.primary,
        },
        title: {
          fillStyle: theme.colors.primary,
        },
      }
    }
    return {
      shape: {
        fillStyle: theme.colors.gray2,
        lineWidth: 0.5,
        color: theme.colors.grayDark,
      },
      title: {
        fillStyle: theme.colors.grayDark,
      },
    }
  }

  onThumbClick = _id => {
    this.setState({ pickedShapeId: _id })
  }

  close = ({ ok } = {}) => {
    // do anything you want on close
    const {
      deformThumbsVertical,
      deformThumbsHorizontal,
      selectVca,
      args,
      deformThumbsHBox,
      deformThumbsVBox,
      importedDbl,
      importedHBox,
      importedVBox,
    } = this.props

    const { pickedShapeId } = this.state

    if (ok) {
      if (pickedShapeId) {
        if (args.type === 'standardShapes') {
          selectVca({
            _id: pickedShapeId,
            fromStandardLib: true,
            hbox: deformThumbsHBox || importedHBox,
            vbox: deformThumbsVBox || importedVBox,
            dbl: importedDbl,
          })
        }

        if (args.type === 'myShapes') {
          selectVca({
            _id: pickedShapeId,
            fromMyLib: true,
            hbox: deformThumbsHBox || importedHBox,
            vbox: deformThumbsVBox || importedVBox,
            dbl: importedDbl,
          })
        }

        if (args.type === 'collection') {
          selectVca({
            _id: pickedShapeId,
            fromCollection: true,
          })
        }
      }
    }
    // reset deform values - we should not need it anymore
    deformThumbsVertical()
    deformThumbsHorizontal()

    // finally hide the popup itself
    this.props.close()
  }

  lazyRenderOnScroll = e => {
    const element = e.nativeEvent.target
    const maxScroll = element.scrollHeight - element.offsetHeight
    const currentScroll = element.scrollTop

    if (maxScroll - currentScroll < 300) {
      requestAnimationFrame(() => {
        this.setState(({ limit }) => ({ limit: limit + LIMIT_STEP }))
      })
    }
  }

  render() {
    const {
      standardLibDeformed,
      myLibDeformed,
      deformThumbsHBox,
      deformThumbsVBox,
      deformThumbsVertical,
      deformThumbsHorizontal,
      args,
      deleteMyShape,
      shapesCollections,
      t,
      isLoading,
    } = this.props

    const { pickedShapeId, searchInputValue, limit } = this.state

    let title
    let lib
    switch (args.type) {
      case 'standardShapes': {
        title = t('standart shape selection')
        lib = standardLibDeformed
        break
      }
      case 'myShapes': {
        title = t('custom shape selection')
        lib = myLibDeformed
        break
      }
      case 'collection': {
        const col = shapesCollections.find(col => col.typeKey === args.collectionKey)
        title = col.name
        lib = col.shapes
        break
      }
      // no default
    }

    // filter shapes
    const searchText = searchInputValue.trim().toLowerCase()
    const filteredLib = lib
      .filter(({ name = '' }) => {
        const nameFilterString = name.trim().toLowerCase()
        return nameFilterString.includes(searchText)
      })
      .slice(0, limit)

    return (
      <PopupPresentational
        title={title}
        close={this.close}
        type="big"
        okText={t('choose shape')}
        cancelText={t('cancel')}
        okDisabled={!pickedShapeId}
        displayChildrenInMessage={false}
        maxWidth="2000px"
        minWidth="unset"
        style={{ width: '90vw' }}
      >
        {lib && lib.length > 0 && (
          <>
            {args.type !== 'collection' && (
              <Row>
                <Text>{t('CUSTOM_SHAPE_SELECT_DESCRIPTION')}</Text>
              </Row>
            )}

            <Row>
              {args.type !== 'collection' && (
                <>
                  <NumberField
                    label={t('HBOX')}
                    name="hbox"
                    onChange={e => {
                      deformThumbsHorizontal(e.value)
                    }}
                    value={deformThumbsHBox}
                  />
                  <Gap />
                  <NumberField
                    label={t('VBOX')}
                    name="vbox"
                    onChange={e => {
                      deformThumbsVertical(e.value)
                    }}
                    value={deformThumbsVBox}
                  />
                  <Gap gap="5rem" />
                </>
              )}
              <TextField
                label={t('search')}
                name="search"
                onChange={e => {
                  this.setState({
                    searchInputValue: e.value,
                    limit: INITIAL_LIMIT,
                  })
                }}
                value={searchInputValue}
              />
            </Row>
          </>
        )}
        <ThumbsWrapper onScroll={this.lazyRenderOnScroll} data-scroll-lock-ignore>
          {filteredLib &&
            filteredLib.map((vca, index) => (
              <ListItem
                key={vca._id}
                vca={vca}
                onClick={this.onThumbClick}
                isPicked={vca._id === pickedShapeId}
                deleteMyShape={deleteMyShape}
                popupType={args.type}
              />
            ))}
        </ThumbsWrapper>
        {!isLoading && (!lib || lib.length === 0) && 
          <Text center>{t('You do not have any shapes yet')}</Text>
        }
        {isLoading && (
          <Row alignItems="center" justifyContent="center">
            <Col alignItems="center" justifyContent="center">
              <Text big center>
                {t('Loading')}
              </Text>
              <Gap />
              <Loading type="puff" />
            </Col>
          </Row>
        )}
      </PopupPresentational>
    )
  }
}

StandardShapeLibPopup.defaultProps = {
  shapesCollections: [],
}

StandardShapeLibPopup.propTypes = {
  close: PropTypes.func.isRequired, // is injected by PopupsGate
  deformThumbsVertical: PropTypes.func.isRequired,
  deformThumbsHorizontal: PropTypes.func.isRequired,
  deleteMyShape: PropTypes.func.isRequired,
  selectVca: PropTypes.func.isRequired,
  standardLibDeformed: PropTypes.array.isRequired,
  shapesCollections: PropTypes.array,
  myLibDeformed: PropTypes.array.isRequired,
  deformThumbsHBox: PropTypes.number.isRequired,
  deformThumbsVBox: PropTypes.number.isRequired,
  args: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const enhance = compose(
  connect(
    state => ({
      standardLibDeformed: state.vca.standardLibDeformed,
      myLibDeformed: state.vca.myLibDeformed,
      deformThumbsVBox: state.vca.deformThumbsVBox,
      deformThumbsHBox: state.vca.deformThumbsHBox,
      shapesCollections: state.vca.shapesCollections,
      isLoading: state.vca.isVcaLoading,
      importedDbl: state.orders.currentOrder?.importedDbl,
      importedHBox: state.orders.currentOrder?.importedHBox,
      importedVBox: state.orders.currentOrder?.importedVBox,
    }),
    {
      deformThumbsVertical,
      deformThumbsHorizontal,
      selectVca,
      deleteMyShape,
    },
  ),
  withTranslation(),
  withTheme,
)

export default enhance(StandardShapeLibPopup)

/* eslint-disable max-classes-per-file */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Input, Label, Errors, Warning, Wrapper, InnerWrapper } from './components'
import { decimalSeparator } from '../../../common/config'
import withMemoize from './withMemoize'

const toFixedPointCoefficient = 100000
const toFixedPoint = number => Math.round(number * toFixedPointCoefficient)
const outOfFixedPoint = number => number / toFixedPointCoefficient

const NumberInput = styled(Input)`
  max-width: ${({ noMaxWidth, theme }) => !noMaxWidth && theme.field.numberFieldMaxWidth};
  min-width: ${({ theme }) => theme.field.numberFieldMinWidth};
  flex-shrink: ${({ shrink }) => shrink && shrink};
  padding-right: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const usedDecimalSeparator = decimalSeparator
const validDecimalSeparatorsRegExp = /\.|,/g
const validCharsRexExp = /[^+\-,.\d]/g

// dot has special meaning in reg exp so it must be escaped
const getDecimalSeparatorForRegExp = () =>
  usedDecimalSeparator === '.' ? '\\.' : usedDecimalSeparator

const getFloatValue = (input = '') => {
  input += ''
  const valueWithDecimalSign = input.replace(usedDecimalSeparator, '.')
  const parsedValue = parseFloat(valueWithDecimalSign)
  return parsedValue
}

class NumberField extends React.PureComponent {
  constructor(props) {
    super(props)
    const { value, decimals } = props
    this.state = {
      fieldValue: this.transformValue(value, decimals),
      amplifierPressed: false,
    }
  }

  componentDidUpdate() {
    const { fieldValue } = this.state
    const { value, decimals } = this.props
    if (!fieldValue && !value && value !== 0) return

    const parsedFromState = getFloatValue(fieldValue)
    const parsedFromRedux = getFloatValue(value)

    // change value in state, if is different in redux after parse
    if (
      !(isNaN(parsedFromState) && isNaN(parsedFromRedux)) &&
      parsedFromState !== parsedFromRedux
    ) {
      this.setState({ fieldValue: this.transformValue(value, decimals) }) // eslint-disable-line
    }
  }

  // move value up or down
  onKeyPress = e => {
    const { onKeyPress, value, step, onChange } = this.props
    const { amplifierPressed } = this.state

    // numbers has to be transformed to fixed decimal point
    // because of floating point precision issues
    const fixedStep = toFixedPoint(step * (amplifierPressed ? 10 : 1))
    const fixedNumberValue = toFixedPoint(getFloatValue(value) || 0)

    const rest = fixedNumberValue % fixedStep
    const x = Math.floor(fixedNumberValue / fixedStep)

    switch (e.which) {
      // shift
      case 16: {
        this.setState({ amplifierPressed: true })
        break
      }
      // UP
      case 38: {
        e.preventDefault()
        if (rest === 0) {
          onChange({ value: outOfFixedPoint(fixedNumberValue + fixedStep) })
        } else {
          onChange({ value: outOfFixedPoint((x + 1) * fixedStep) })
        }
        break
      }
      // DOWN
      case 40: {
        e.preventDefault()
        if (rest === 0) {
          onChange({ value: outOfFixedPoint(fixedNumberValue - fixedStep) })
        } else {
          onChange({ value: outOfFixedPoint(x * fixedStep) })
        }
        break
      }
      default:
    }
    onKeyPress(e)
  }

  onKeyReleased = e => {
    // shift
    if (e.which === 16) {
      this.setState({ amplifierPressed: false })
    }
  }

  onChange = e => {
    const { onChange } = this.props
    let newInputValue = e.target.value
    newInputValue = newInputValue.trim()

    if (!newInputValue) {
      onChange({ value: newInputValue })
      this.setState({ fieldValue: newInputValue })
      return
    }

    // odstranit všechny nepovolené znaky
    newInputValue = newInputValue.replace(validCharsRexExp, '')

    // nahradit tečku nebo čárku výsledným znakem
    newInputValue = newInputValue.replace(validDecimalSeparatorsRegExp, usedDecimalSeparator)

    // čárka nemůže být první znak
    newInputValue = newInputValue.replace(/^,/, '')

    // + nebo - může být první znak, když je dál, tak odstranit
    if (newInputValue[0]) {
      newInputValue = newInputValue[0] + newInputValue.substring(1).replace(/[+-]/g, '')
    }

    // smazat dividery, pokud jich je více
    const beforeDivider = newInputValue.split(new RegExp(getDecimalSeparatorForRegExp()), 1)[0]
    const afterDivider = newInputValue.substring(beforeDivider.length + 1)
    if (afterDivider) {
      newInputValue =
        beforeDivider +
        usedDecimalSeparator +
        afterDivider.replace(new RegExp(getDecimalSeparatorForRegExp(), 'g'), '')
    }
    this.setState({ fieldValue: newInputValue })
    const valueWithDecimalSign = newInputValue.replace(usedDecimalSeparator, '.')
    const parsedValue = parseFloat(valueWithDecimalSign)
    if (!isNaN(parsedValue)) {
      onChange({ value: parsedValue })
    } else {
      onChange({ value: '' })
    }
  }

  transformValue = (value, decimals) => {
    const { showSign } = this.props
    const numberValue = parseFloat(value)
    const isPositive = numberValue > 0
    let newInputValue = decimals >= 0 ? numberValue.toFixed(decimals) : `${value}`
    newInputValue = newInputValue.trim()
    // remove forbidden chars
    newInputValue = newInputValue.replace(validCharsRexExp, '')
    // replace decimal separator
    newInputValue = newInputValue.replace(validDecimalSeparatorsRegExp, usedDecimalSeparator)
    if (showSign && isPositive && !newInputValue.startsWith('+')) {
      newInputValue = `+${newInputValue}`
    }
    return newInputValue
  }

  render() {
    const {
      disabled,
      errors,
      label,
      onBlur,
      required,
      name,
      className,
      warning,
      tooltip,
      after,
      noMaxWidth,
      innerRef,
      errorsStyle,
      ...props
    } = this.props
    const { fieldValue } = this.state

    return (
      <Wrapper className={className}>
        {label && (
          <Label htmlFor={name} required={required} tooltip={tooltip}>
            {label}
          </Label>
        )}
        <InnerWrapper>
          <NumberInput
            ref={innerRef}
            id={name}
            disabled={!!disabled}
            name={name}
            required={required || undefined}
            onBlur={e => {
              const newValue = onBlur(e)
              this.setState({ fieldValue: this.transformValue(newValue) })
            }}
            noMaxWidth={noMaxWidth}
            isValid={!errors || errors.length === 0}
            {...props}
            onChange={this.onChange}
            value={fieldValue}
            onKeyDown={this.onKeyPress}
            onKeyUp={this.onKeyReleased}
            type="text"
            inputMode="numeric"
          />
          {after}
        </InnerWrapper>
        {!!errors && <Errors errors={errors} style={errorsStyle} />}
        {!!warning && <Warning text={warning} />}
      </Wrapper>
    )
  }
}

NumberField.defaultProps = {
  children: null,
  disabled: false,
  errors: null,
  className: '',
  onBlur: () => {},
  required: false,
  type: 'number',
  value: '',
  label: '',
  warning: '',
  tooltip: '',
  min: undefined,
  max: undefined,
  after: null,
  noMaxWidth: false,
  innerRef: null,
  errorsStyle: null,
  onKeyPress: () => null,
  step: 1,
  showSign: false,
  decimals: -1,
}

NumberField.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  warning: PropTypes.string,
  tooltip: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  after: PropTypes.node,
  noMaxWidth: PropTypes.bool,
  innerRef: PropTypes.func,
  errorsStyle: PropTypes.object,
  onKeyPress: PropTypes.func,
  step: PropTypes.number,
  showSign: PropTypes.bool,
  decimals: PropTypes.number,
}

export default withMemoize(NumberField)
